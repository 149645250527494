import { update } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addNextSection, makeLastIndex, removeSection, setIdxCount, updateSection } from 'redux/formSlice';
import { ReducerState, Section } from 'types';

const useIndexedSection = () => {
  const dispatch = useDispatch();
  const form = useSelector((state: ReducerState) => state?.form?.currentPage);
  const { dependentId = '', pageId = '' } = useParams();
  const caseId = useSelector((state: ReducerState) => state.client.currentCase);




  const getsessionStorageString = (ptvKey: any = '') => {
    let localId = pageId + '/' + caseId + '/return/' + dependentId; // TODO: follow by the depedent id
    if (ptvKey instanceof Array) {
      for (var wordIndex in ptvKey) {
        localId = localId + '/' + ptvKey[wordIndex];
      }
      return localId;
    } else {
      return '';
    }
  };

  const addAnother = (section: Section, sectionIndex: number) => {
    const nullifyInSessionStorage = (ptv: string, prompt: string) => {
      sessionStorage.setItem(
        getsessionStorageString(ptv),
        JSON.stringify({
          value: null,
          prompt,
          text: '',
          isImmpactVal: true,
        })
      );
    };

    const newIdxCount = (parseInt(section.idxCount) || 1) + 1;
    // update the value of the input
    sessionStorage.setItem(
      getsessionStorageString(['idxCount_' + section?.id]),
      JSON.stringify({
        value: newIdxCount,
        isImmpactVal: true,
        prompt: '',
        text: String(newIdxCount),
      })
    );

    // go through the inputs and update the index of the path to value
    const { id, isIndexed, isLastIndex = false, description = undefined, inputs = [], name = undefined } = section;
    let updatedSection: Section = {
      id,
      name,
      isIndexed,
      description,
      inputs: [],
      isLastIndex,
    };
    section?.inputs?.forEach((input) => {
      if (input.desc) {
        updatedSection.inputs?.push(input);
        return;
      }
      const newIndex = (parseInt(input.pathToValue?.['0']?.[input.pathToValue?.['0']?.length - 1]) + 1).toString();
      let newIdxPathToValue = [...input.pathToValue['0']];
      newIdxPathToValue[newIdxPathToValue.length - 1] = newIndex;
      const newPathToValue = {
        ...input.pathToValue,
        '0': newIdxPathToValue,
      };
      updatedSection.inputs?.push({
        ...input,
        pathToValue: newPathToValue,
        value: {
          ...input.value,
          value: undefined,
        },
      });
      nullifyInSessionStorage(newPathToValue['0'], input.prompt);
    });
    updatedSection.idxCount = newIdxCount;
    dispatch(
      makeLastIndex({
        sectionIndex,
        value: false,
      })
    );
    dispatch(
      addNextSection({
        section: updatedSection,
        sectionIndex,
      })
    );
  };

  const getSectionIndex = (section: Section) => {
   return section.idxCount - 1 || 0;
  };





// REMOVE PREVIOUS SECTION


  const removePrevious = (section: Section, sectionIndex: number) => {
    let firstActualInputIndex = 0;
    if (section?.inputs?.[0]?.desc) {
      firstActualInputIndex = 1;
    }

    const index =
      section?.inputs?.[firstActualInputIndex].pathToValue[
        section?.inputs?.[firstActualInputIndex].pathToValue.length - 1
      ];

    dispatch(
      removeSection({
        sectionIndex,
      })
    );

    console.log("SECTION", section);  

    // section reindexing logic

    // while there is still another indexed section after current section (isLastIndex = false)
    // for each input in the next section, update the pathToValue to be the index of the previous section
    // update the idxCount of the next section to be the idxCount of the previous section
    // update the section in redux store

    if (section.isLastIndex) {
      dispatch(
        makeLastIndex({
          sectionIndex: sectionIndex - 1,
          value: true,
        })
      );
      
      const newIdxCount = section.idxCount - 1;
      sessionStorage.setItem(
        getsessionStorageString(['idxCount_' + section?.id]),
        JSON.stringify({
          value: newIdxCount,
          isImmpactVal: true,
          prompt: '',
          text: String(newIdxCount),
        })
      );
    } else {
      let extraIndices = 1;

      while (form.sections?.[sectionIndex + extraIndices]?.isLastIndex === false) {
        const nextSection = form.sections[sectionIndex + extraIndices];
        let updatedSection: Section = {
          ...nextSection,
          idxCount: nextSection.idxCount - 1,
          isLastIndex: false,
          inputs: [],
        };
        nextSection?.inputs?.forEach((input) => {
          if (input.desc) {
            updatedSection.inputs?.push(input);
            return;
          }
          // change index to be the index of the previous section
          const newIndex = (parseInt(input.pathToValue?.['0']?.[input.pathToValue?.['0']?.length - 1]) - 1).toString();
          let newIdxPathToValue = [...input.pathToValue['0']];
          newIdxPathToValue[newIdxPathToValue.length - 1] = newIndex;

          // update the pathToVaule such that it saves to new value
          const newPathToValue = {
            ...input.pathToValue,
            '0': newIdxPathToValue,
          };

          const sessionStorageValue = JSON.parse(
            sessionStorage.getItem(getsessionStorageString(input?.pathToValue?.['0'])) || `{}`
          )?.value;
          // reflect on session storage
          sessionStorage.setItem(
            getsessionStorageString(newPathToValue['0']),
            JSON.stringify({
              value: sessionStorageValue || input?.value?.value || null,
              prompt: input?.prompt,
              text: '',
              isImmpactVal: true,
              format: input?.validation?.ymOnly ? 'YYYYMM' : 'YYYYMMDD',
            })
          );
          updatedSection.inputs?.push({
            ...input,
            pathToValue: newPathToValue,
          });
        });
        dispatch(
          updateSection({
            sectionIndex: sectionIndex + extraIndices - 1,
            section: updatedSection,
          })
        );
        extraIndices++;
      }

      const nextSection = form.sections[sectionIndex + extraIndices];
      let updatedSection: Section = {
        ...nextSection,
        isLastIndex: true,
        idxCount: nextSection.idxCount - 1,
        inputs: [],
      };
      nextSection?.inputs?.forEach((input) => {
        if (input.desc) {
          updatedSection.inputs?.push(input);
          return;
        }
        // change index to be the index of the previous section
        const newIndex = (parseInt(input.pathToValue?.['0']?.[input.pathToValue?.['0']?.length - 1]) - 1).toString();
        let newIdxPathToValue = [...input.pathToValue['0']];
        newIdxPathToValue[newIdxPathToValue.length - 1] = newIndex;

        // update the pathToVaule such that it saves to new value
        const newPathToValue = {
          ...input.pathToValue,
          '0': newIdxPathToValue,
        };

        const sessionStorageValue = JSON.parse(
          sessionStorage.getItem(getsessionStorageString(input?.pathToValue?.['0'])) || `{}`
        )?.value;

        // reflect on session storage
        sessionStorage.setItem(
          getsessionStorageString(newPathToValue['0']),
          JSON.stringify({
            value: sessionStorageValue || input?.value?.value || null,
            prompt: input?.prompt,
            text: '',
            isImmpactVal: true,
            format: input?.validation?.ymOnly ? 'YYYYMM' : 'YYYYMMDD',
          })
        );

        sessionStorage.removeItem(getsessionStorageString(input?.pathToValue?.['0']));

        updatedSection.inputs?.push({
          ...input,
          pathToValue: newPathToValue,
        });
      });
      dispatch(
        updateSection({
          sectionIndex: sectionIndex + extraIndices - 1,
          section: updatedSection,
        })
      );
      const newIdxCount = updatedSection.idxCount;
      sessionStorage.setItem(
        getsessionStorageString(['idxCount_' + section?.id]),
        JSON.stringify({
          value: newIdxCount,
          isImmpactVal: true,
          prompt: '',
          text: String(newIdxCount),
        })
      );
    }
  };

  return {
    addAnother,
    removePrevious,
    getSectionIndex,
  };
};

export default useIndexedSection;
function getTextFromValue(value: any) {
  throw new Error('Function not implemented.');
}
