'use client';

import { useState, useEffect } from 'react';
import { Link } from '@mui/material';
import Logo from './logo';
import Dropdown from '../../components/utils/dropdown';
import MobileMenu from './mobile-menu';
import { useTranslation, Trans } from 'react-i18next';
import { useStripe } from 'utils/hooks';

const lngs = {
  en: { flag: '🇬🇧' },
  fr: { flag: '🇫🇷' },
};

export default function Header() {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  const toggleLanguage = () => {
    const newLang = currentLang === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(newLang);
    setCurrentLang(newLang);
  };

  const getAlternateLangFlag = () => {
    return currentLang === 'en' ? lngs['fr'].flag : lngs['en'].flag;
  };

  const { redirectToCheckout } = useStripe();

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top ? 'bg-white backdrop-blur-sm shadow-lg' : ''
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="flex items-center">
            <div className="shrink-0 mr-4">
              <Logo />
            </div>
            <span className="text-2xl font-bold font-serif"></span>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            {/* Desktop sign in links */}
            <ul className="tw-ul flex grow justify-end flex-wrap items-center">
              <div>
                <button
                  style={{
                    fontSize: '1.5em',
                    padding: '5px',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    transition: 'transform 0.2s ease',
                  }}
                  type="button"
                  onClick={toggleLanguage}
                  onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.2)')}
                  onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                >
                  {getAlternateLangFlag()}
                </button>
              </div>
              <li>
                <div
                  onClick={redirectToCheckout}
                  className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out no-underline cursor-pointer"
                >
                  {' '}
                  <Trans i18nKey="description.part1"></Trans>
                </div>
              </li>
              <li>
                {/* SIGN-UP */}
                {/* <Link href="/login" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3"> <Trans i18nKey="description.part2"></Trans> */}
                {/* <svg className="w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg> */}
                {/* </Link> */}
              </li>
              {/* Language switch buttons */}
              <li></li>
            </ul>
          </nav>

          <MobileMenu />
        </div>
      </div>
    </header>
  );
}
