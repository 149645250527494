import { useApiClient } from 'apiClient';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Typography,
  Stack,
  Container,
  Button,
  CircularProgress,
  Grid,
  Box,
  Collapse,
  IconButton,
} from '@mui/material';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import { ReducerState } from 'types';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import FormSelector from 'components/form-input/FormSelector';
import { InputType, Subsection } from 'types';
import useIndexedSection from 'utils/hooks/useIndexedSection';
import { toggleSectionCollapse } from 'redux/formSlice';
import Iconify from 'components/iconify';

const getInputlength = (subsectionLength: number, index: number, inputType?: InputType) => {
  const maxInputsPerRow = 3;

  if (inputType === InputType.RADIO || inputType === InputType.TEXT_BOX || inputType == InputType.BUTTON) {
    return 12;
  }

  if (subsectionLength > maxInputsPerRow) {
    let leftOver = subsectionLength - Math.floor(subsectionLength / maxInputsPerRow) * maxInputsPerRow;
    if (index < Math.floor(subsectionLength / maxInputsPerRow) * maxInputsPerRow) {
      return 3.95;
    } else {
      return Math.min(12 / leftOver, 6);
    }
  } else {
    return Math.min(12 / subsectionLength, 6);
  }
};

const FormPage = () => {
  const { getCurrentPage, savePage, getCasePages } = useApiClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageData = useSelector((state: ReducerState) => state.form.currentPage);
  const language = useSelector((state: ReducerState) => state.account.language);
  const { addAnother, removePrevious, getSectionIndex } = useIndexedSection();
  const { sections, pageName } = pageData;
  const { pageId = '', dependentId = '' } = useParams();
  const isCurrentPageLoading = useSelector((state: ReducerState) => state.form.isCurrentPageLoading);


  // detect "isCurrentPageLoading" and "pageId" changes
 useEffect(() => {
    getCurrentPage(pageId, dependentId);
    console.log('pageId', pageId);
  }, [pageId, language]);


  useEffect(() => {
    getCasePages(dependentId);
  }, [language]);
  const handleReturn = () => {
    navigate(`../case/dependents`);
  };
  return (
    <>
      {!isCurrentPageLoading ? (
        <Container>
          <Stack direction={'row'} justifyContent="flex-end">
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {pageName}
            </Typography>
            <Button variant="contained" endIcon={<Iconify icon="eva:arrow-forward-outline" />} onClick={handleReturn}>
              Return
            </Button>
          </Stack>
          {sections?.map?.((section, sectionIndex) => {
            return (
              <Card sx={{ marginBottom: 5 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  mb={5}
                  paddingTop={5}
                  paddingLeft={5}
                >
                  <IconButton onClick={() => dispatch(toggleSectionCollapse({ sectionIndex }))}>
                    {section.collapsed ? <ArrowDropDown /> : <ArrowRight />}
                  </IconButton>
                  <Typography variant="h4">
                    {section.name[language]}{' '}
                    {section.isIndexed &&
                      '(' +
                        (parseInt(
                          section?.inputs[0 + (section.inputs[0].desc ? 1 : 0)]?.pathToValue['0'][
                            section?.inputs[0 + (section.inputs[0].desc ? 1 : 0)]?.pathToValue['0'].length - 1
                          ]
                        ) +
                          1) +
                        ')'}
                  </Typography>
                </Stack>
                <Collapse in={section?.collapsed}>
                  <Stack direction="column" alignItems="center" justifyContent="space-between" mb={5} paddingBottom={5}>
                    <Typography variant="body1" textAlign={'left'} width={'95%'}>
                      {section?.description}
                    </Typography>
                    <Grid container rowGap={3} marginTop={4}>
                      {section?.inputs?.map?.((input, index) => {
                        // indxed subsection detection logic

                        return input.desc ? (
                          <Typography variant="h6" sx={{ width: '100%', paddingLeft: '5%' }}>
                            {input.desc?.[language]}
                          </Typography>
                        ) : (
                          !input?.hidden && (
                            <Grid item xs={getInputlength(section?.inputs?.length, index, input?.inputType)}>
                              <FormSelector
                                key={uuidv4()}
                                input={input}
                                sectionIndex={sectionIndex}
                                inputIndex={index}
                              />
                            </Grid>
                          )
                        );
                      })}
                      {section?.isIndexed && (
                        <Stack
                          direction={'row'}
                          sx={{
                            width: '95%',
                            padding: '2%',
                          }}
                        >
                          {' '}
                          {section?.isLastIndex && (
                            <Button
                              variant="outlined"
                              sx={{ marginRight: '5%' }}
                              onClick={() => {
                                addAnother(section, sectionIndex);
                              }}
                            >
                              Add another
                            </Button>
                          )}
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              removePrevious(section, sectionIndex);
                            }}
                            disabled={getSectionIndex(section) === 0 && section?.isLastIndex}
                          >
                            Remove
                          </Button>
                        </Stack>
                      )}
                      {/* if indexed add add another and remove another button */}
                    </Grid>
                  </Stack>
                </Collapse>
              </Card>
            );
          })}
          <Button onClick={() => savePage(pageId)}>Save</Button>
        </Container>
      ) : (
        <Box sx={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default FormPage;
