import { Link } from '@mui/material';
import Pact from "../../images/pact5.png"

export default function Logo() {
  return (
    <Link href="/" className="block" aria-label="Cruip">
      <img 
        src={Pact} 
        alt="Pact Logo" 
        className="w-8 h-8"  
      />
    </Link>
  )
}
