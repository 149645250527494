import { alpha, OutlinedInput, styled } from '@mui/material';

export const StandardInput = styled(OutlinedInput)(({ theme }) => ({
  width: '90%',
  marginLeft: '1.7rem',
  marginBottom: '5%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: '92%',
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    minHeight: '60px',
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
