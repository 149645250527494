export const enum ModalType {
  NEW_USER = "new user",
  DELETE_USER = "delete user",
  DELETE_CASE = "delete case",
  UPDATE_USER = "update user",
  ADD_REP_CODE = "add rep code",
  ADD_CASE = "add case",
  ADD_DEPENDENT = "add dependent",
  REPORT_PROBLEM = "report problem",
  AUTOMATE = "automate",
  REP_ID = "rep id", 
  SELECT_DOC_SCANNER = "select doc scanner",
}

export const enum TableType {
  USER = "user",
  CASE = "case"
}