import {
    alpha,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    Modal,
    OutlinedInput,
    styled,
    TextField,
    Typography,
    IconButton
  } from '@mui/material';
  import { useApiClient } from 'apiClient';
  import { StandardInput } from 'components/form-input';
  import Iconify from 'components/iconify';
  import { AstPath } from 'prettier';
  import React, { useState, useEffect } from 'react';
  import { useTranslation } from 'react-i18next';
  import { AlertSeverity } from 'types';
  import { useDispatch } from 'react-redux';
  import { sendMessage } from 'redux/alertSlice';
  
  
  interface ModalProps {
    open: boolean;
    handleClose: () => void;
  }
  
  const RepIdModal = ({ open, handleClose }: ModalProps) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { reportProblem } = useApiClient();
    const [repCode, setRepCode] = useState('');
    const { createRepCode, createUser } = useApiClient();
    const dispatch = useDispatch();
    const handleSubmit = async () => {
      reportProblem(title, description);
      handleClose();
    };
    useEffect(() => {
        createRepCode().then((response) => {
          setRepCode(response);
        });
      }, []);
    
    
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ padding: '5%', marginLeft: '2%' }}>{t('repId.code')}</DialogTitle>
        <DialogContent >
        <TextField
          value={repCode}
          name="Rep ID"
          label="Rep ID"
          margin="dense"
          contentEditable={false}
          disabled
          fullWidth
          
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(repCode);
                    dispatch(
                      sendMessage({
                        open: true,
                        message: 'Copied to clipboard',
                        severity: AlertSeverity.SUCCESS,
                      })
                    );
                  }}
                  edge="end"
                >
                    
                  <Iconify icon={'ph:clipboard'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
  </DialogContent>
        <DialogActions>
          
          <Button onClick={handleClose}>{t('dashboard.reportCancel')}</Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default RepIdModal;