import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// api Client
import { useLogin } from 'apiClient';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../iconify';
import { useDispatch } from 'react-redux';
import { sendMessage } from 'redux/alertSlice';
import { AlertSeverity } from 'types';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register } = useLogin();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const validateCreatedPassword = (password: string, confirmPassword: string) => {
    if (password === confirmPassword) {
      return true;
    } else {
      dispatch(
        sendMessage({
          open: true,
          message: t('signUpForm.passwordMismatchError'),
          severity: AlertSeverity.ERROR,
        })
      );
      return false;
    }
  };

  const handleClick = () => {
    if (validateCreatedPassword(password, confirmPassword)) {
      // use the register function and apply names to the metadata
      register(firstName, lastName, email, password);
    } else {
      return;
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="first name"
          label={t('signUpForm.firstName')}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
        <TextField
          name="last name"
          label={t('signUpForm.lastName')}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />

        <TextField
          name="email"
          label={t('signUpForm.emailAddress')}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <TextField
          name="password"
          label={t('signUpForm.password')}
          type={'password'}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <TextField
          name="confirm password"
          label={t('signUpForm.confirmPassword')}
          type={'password'}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
      {t('signUpForm.signupButton')}
      </LoadingButton>
    </>
  );
}
