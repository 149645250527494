import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useTranslation } from 'react-i18next';

const CustomForm = ({ status, message, onValidated }) => {
  const { t } = useTranslation();
  let email;
  const submit = () => 
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

    const inputStyle = {
      
      padding: '10px 20px',       
      marginRight: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      width: '250px',           
      fontSize: '16px',        
      outline: 'none',          
      boxSizing: 'border-box',  
    };
    

    const buttonStyle = {
      padding: '10px 20px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#2563EB',
      color: 'white',
      cursor: 'pointer',
    };

  return (
    <div>
      <input
        ref={node => (email = node)}
        type="email"
        placeholder={t('newsletter.emailPlaceholder')}
        style={inputStyle}
      />
      <button onClick={submit} style={buttonStyle}>
      {t('newsletter.subscribeButton')}
      </button>
      {status === "sending" && <div style={{ color: "blue" }}>{t('newsletter.sendingStatus')}</div>}
      {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{ __html: message }}/>}
      {status === "success" && <div style={{ color: "green" }}>{t('newsletter.successStatus')}</div>}
    </div>
  );
};

export default function Newsletter() {
  const { t } = useTranslation();
  const url = "https://immpact-as.us21.list-manage.com/subscribe/post?u=c65f997e9029e7c9ae0f11ba9&amp;id=c505b3a16b&amp;f_id=0085f7e6f0"; 



return(
<section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          <div className="relative bg-gray-900 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden">
            {/* Background illustration */}
            <div className="absolute right-0 bottom-0 pointer-events-none hidden lg:block" aria-hidden="true">
              <svg width="428" height="328" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient cx="35.542%" cy="34.553%" fx="35.542%" fy="34.553%" r="96.031%" id="ni-a">
                    <stop stopColor="#DFDFDF" offset="0%" />
                    <stop stopColor="#4C4C4C" offset="44.317%" />
                    <stop stopColor="#333" offset="100%" />
                  </radialGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <g fill="#FFF">
                    <ellipse fillOpacity=".04" cx="185" cy="15.576" rx="16" ry="15.576" />
                    <ellipse fillOpacity=".24" cx="100" cy="68.402" rx="24" ry="23.364" />
                    <ellipse fillOpacity=".12" cx="29" cy="251.231" rx="29" ry="28.231" />
                    <ellipse fillOpacity=".64" cx="29" cy="251.231" rx="8" ry="7.788" />
                    <ellipse fillOpacity=".12" cx="342" cy="31.303" rx="8" ry="7.788" />
                    <ellipse fillOpacity=".48" cx="62" cy="126.811" rx="2" ry="1.947" />
                    <ellipse fillOpacity=".12" cx="78" cy="7.072" rx="2" ry="1.947" />
                    <ellipse fillOpacity=".64" cx="185" cy="15.576" rx="6" ry="5.841" />
                  </g>
                  <circle fill="url(#ni-a)" cx="276" cy="237" r="200" />
                </g>
              </svg>
            </div>

            <div className="relative flex flex-col lg:flex-row justify-between items-center">
              <div className="text-center lg:text-left lg:max-w-xl">
                <h3 className="tw-h3 h3 text-white mb-2">{t('newsletter.newsletterTitle')}</h3>
                <p className="text-gray-300 text-lg mb-6">{t('newsletter.newsletterSubtitle')}</p>

                <MailchimpSubscribe
                  url={url}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
)}


{/*
  return (
    <section>
      
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
      />
    </section>
  );
}

*/}