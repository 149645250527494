import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

// @mui
import { Stack, Button, Container, Typography } from '@mui/material';
import { useApiClient } from 'apiClient';
import ImmpactTable from 'landing/components/ui/table';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export default function SettingsPage() {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 300,
    maxHeight: '90%',
    maxWidth: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #3b82f6', // Updated the color to blue
    boxShadow: 24,
    p: 4,
    borderRadius: '10px', // Added this to round the corners of the modal
    overflow: 'auto',
  };

  const { t } = useTranslation();
  const { deleteAccount } = useApiClient();

  return (
    <>
      <Helmet>
        <title> Users | Immpact </title>
      </Helmet>

      <Container>
        <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('dashboard.settings')}
          </Typography>
          <Typography variant='body1' gutterBottom>
            subscription and billing cycles
          </Typography>
          <ImmpactTable columnHeader={[]} tableValues={[]} tableFunctions={[]} searchPlaceHolder={''} />
          <Button variant="outlined" color="error" onClick={openDeleteModal} sx={{ marginTop: '5%' ,maxWidth: '300px'}}>
            {t('dashboard.deleteAccount')}
          </Button>
        </Stack>

        <Modal
          open={isDeleteModalOpen}
          onClose={closeDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={6}>
              {t('dashboard.deleteAccountHeader')}
            </Typography>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  deleteAccount();
                  closeDeleteModal();
                }}
              >
                {t('dashboard.deleteAccount')}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Container>
    </>
  );
}
