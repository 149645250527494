import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
  
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../components/user';
// mock
// import LIST from '../_mock/user';
import { useApiClient } from 'apiClient';
import { useSelector } from 'react-redux';
import { ReducerState } from 'types/types';
import SelectModal from 'components/action-modals/SelectModal';
import { ModalType } from 'types';
import { useNavigate } from 'react-router-dom';
import { useIPR } from 'utils/hooks';
import ImmpactTable from 'landing/components/ui/table';


// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export default function TablePage() {
  const [modalOpen, setModalOpen] = useState<ModalType>(null);
  const [args, setArgs] = useState<any>(null);
  const { getAllUsers, getallCases } = useApiClient();
  const navigate = useNavigate();

  const data = useSelector((state: ReducerState) => state.client.value);
  const processedData: any[][] = [];
  data.forEach((row, index) => {
    processedData[index] = [];
    processedData[index][0] = { label: row.givenName, align: 'left' };
    processedData[index][1] = { label: row.lastName, align: 'left' };
    processedData[index][2] = { label: row.caseCount, align: 'right' };
  });
  const handleCloseModal = () => {
    setModalOpen(undefined);
  };

  useEffect(() => {
    // TODO: change this to local rep id
    getAllUsers();
  }, []);

  const handleNew = async () => {
    setModalOpen(ModalType.NEW_USER);
  };

  const { t } = useTranslation();

  return (
    <>
      <SelectModal open={modalOpen} handleClose={handleCloseModal} args={args} />
      <Helmet>
        <title> Users | Immpact </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          {t('dashboard.user')}
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleNew}>
            {t('dashboard.newUser')}
          </Button>
        </Stack>

        <ImmpactTable
          columnHeader={[
            { id: 'givenName', label: t('dashboard.firstName'), alignRight: false },
            { id: 'lastName', label: t('dashboard.familyName'), alignRight: false },
            { id: 'caseCount', label: t('dashboard.numberOfCases'), alignRight: true },
            { id: '', label: '', alignRight: false },
          ]}
          tableValues={processedData}
          tableFunctions={[
            {
              label: t('dashboard.viewCases'),
              logo: 'carbon:view-filled',
              function: (currentSelection) => {
                getallCases(data[currentSelection].id);
                navigate(`../cases/${data[currentSelection].id}`, { replace: true });
              },
            },
            {
              label: t('dashboard.deleteClient'),
              logo: 'eva:trash-2-outline',
              function: (currentSelection) => {
                setArgs({ id: data[currentSelection].id });
                setModalOpen(ModalType.DELETE_USER);
              },
            },
          ]}
          searchPlaceHolder={''}
        />
      </Container>
    </>
  );
}
