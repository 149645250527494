import React, { useState } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { StandardInput } from 'components/form-input';
import { useDispatch } from 'react-redux';
import { sendMessage } from 'redux/alertSlice';
import { useNavigate } from 'react-router-dom';import { AlertSeverity } from 'types';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const auth = getAuth();
  const sendEmail = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch(sendMessage({ open: true, message: 'Email sent, check your inbox!', type: AlertSeverity.SUCCESS }));
      })
      .catch((error) => {
        const errorMessage = error.message;
        dispatch(sendMessage({ open: true, message: errorMessage, type: AlertSeverity.ERROR }));
      });
  };
  return (
    <Grid sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction={'column'} sx={{ width: '50%' }}>
        <Button variant="contained" sx={{ width: '15%', marginBottom: '20%' }} onClick={() => navigate(-1)}>
          &#8592; Return
        </Button>
        <Typography sx={{ marginBottom: '5%' }} variant="h4">
          Forgot Password
        </Typography>
        <Typography sx={{ marginBottom: '3%' }}>
          Please enter your email address below and we will send you a link to reset your password.
        </Typography>
        <StandardInput
          type="email"
          sx={{ width: '100%', marginLeft: 0 }}
          placeholder="type in your email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button variant="contained" onClick={sendEmail}>
          Send Email
        </Button>
      </Stack>
    </Grid>
  );
};

export default ForgotPassword;
