import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// api Client
import { useLogin } from 'apiClient';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../iconify';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleClick = () => {
    login(email, password);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label={t('loginForm.emailAddress')} onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label={t('loginForm.password')}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link
          variant="subtitle2"
          underline="hover"
          sx={{ cursor: 'pointer' }}
          href={window.location.origin+ '/forgot-password'}
        >
          {t('loginForm.forgotPassword')}
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        {t('loginForm.loginButton')}
      </LoadingButton>
    </>
  );
}
