import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useApiClient } from 'apiClient';
import { StandardInput } from 'components/form-input';
import Iconify from 'components/iconify';
import { AstPath } from 'prettier';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { customStyles } from 'components/form-input';
import ReactSelect from 'react-select';
import { Dependant, ReducerState } from 'types';
import { useNavigate } from 'react-router-dom';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

const ScannerModal = ({ open, handleClose }: ModalProps) => {
  const { t } = useTranslation();
  const [uploadComplete, setUploadComplete] = useState(false);
  const [selectedDep, setSelectedDep] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState('passport');
  const [loading, setLoading] = useState(false);
  const { scanPassport, savePage } = useApiClient();
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const dependants = useSelector((state: ReducerState) => state?.form?.dependants);
  const caseId = useSelector((state: ReducerState) => state?.client?.currentCase);

  const getDependantTypeLabel = (formType: string = '') => {
    switch (formType) {
      case 'MainApplicant':
        return 'Main Applicant';
      case 'DependantsMinor':
        return 'Dependant';
      case 'Dependants18Plus':
        return 'Dependant';
      default:
        return 'Dependant';
    }
  };

  const SUPPORTED_DOCUMENTS = [
    {
      value: 'passport',
      label: 'Passport',
    },
  ];

  const goToForm = () => {
    navigate(`../../dashboard/forms/Government%20Documents/${selectedDep}`);
    console.log(window.location);
  };

  const clearAndClose = () => {
    setSelectedDoc('');
    setLoading(false);
    setUploadComplete(false);
    handleClose();
  };
  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const getsessionStorageString = (ptvKey: any = '') => {
    let localId = 'Government Documents' + '/' + caseId + '/return/' + selectedDep; // TODO: follow by the depedent id

    localId = localId + '/' + ptvKey;

    return localId;
  };

  const handleChange = async (event: { target: { files: any[] } }) => {
    const fileUploaded = event.target.files[0];
    setLoading(true);
    await scanPassport(fileUploaded).then(async (response) => {
      console.log(response);

      sessionStorage.setItem(
        getsessionStorageString('validPassportYes'),
        JSON.stringify({
          value: 'validPassportYes',
          prompt: {
            en: 'Do you have a valid passport/travel document?',
            fr: 'Avez-vous un passeport/document de voyage valide ?',
          },
          text: 'validPassportYes',
          isImmpactVal: true,
        })
      );

      for (var entry in response.data) {
        sessionStorage.setItem(
          getsessionStorageString(entry),
          JSON.stringify({
            value: response.data[entry]?.value,
            prompt: response.data[entry]?.prompt || '',
            text: response.data[entry]?.text,
            format: response.data[entry]?.format,
            isImmpactVal: true,
          })
        );
      }

      await savePage('Government Documents');

      // sessionStorage.setItem(
      //   getsessionStorageString([]),
      //   JSON.stringify({
      //     value: e.target.value,
      //     prompt: input.prompt,
      //     text: g
      //     isImmpactVal: true,
      //   })
      // );
      setUploadComplete(true);
    });
    setLoading(false);
  };

  const getDependantLabel = (dependant: Dependant, index: number) => {
    return `${getDependantTypeLabel(dependant?.formType?.value?.toString())}${index ? index + ': ' : ': '} ${
      dependant?.givenName?.value || ''
    } ${dependant.lastName?.value || ''}`;
  };

  const filterDependants = () => {
    return dependants?.map?.((dependant, index) => {
      return {
        value: dependant.id,
        label: getDependantLabel(dependant, index),
      };
    });
  };

  console.log('upload complete', uploadComplete);

  return (
    <Dialog open={open} onClose={clearAndClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: '5%', marginLeft: '2%' }}>Scan Document</DialogTitle>
      {uploadComplete ? (
        <DialogContent
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        >
          <Typography variant="body1" marginLeft={'5%'} marginBottom={'5%'}>
            {' '}
            Document successfully uploaded{' '}
          </Typography>

          <Button onClick={goToForm} startIcon={<Iconify icon="mdi:check" />}>
            Go to form
          </Button>
        </DialogContent>
      ) : loading ? (
        <DialogContent
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        >
          <Typography variant="body1" marginLeft={'5%'} marginBottom={'5%'}>
            {' '}
            scanning...
          </Typography>
          <CircularProgress />
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="body1" marginLeft={'5%'} marginBottom={'5%'}>
            {' '}
            Please select the type of document you would like to scan{' '}
          </Typography>
          <ReactSelect
            menuPortalTarget={document.body}
            styles={customStyles}
            options={SUPPORTED_DOCUMENTS}
            onChange={(selection) => {
              setSelectedDoc(selection?.value || '');
            }}
            minMenuHeight={200}
            maxMenuHeight={200}
          />
          <Typography variant="body1" marginLeft={'5%'} marginBottom={'5%'}>
            {' '}
            Please select the type of document you would like to scan{' '}
          </Typography>
          <ReactSelect
            menuPortalTarget={document.body}
            styles={customStyles}
            options={filterDependants()}
            onChange={(selection) => {
              setSelectedDep(selection?.value || '');
            }}
            minMenuHeight={200}
            maxMenuHeight={200}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClick} disabled={!selectedDoc || !selectedDep} startIcon={<Iconify icon="mdi:upload" />}>
          Upload
        </Button>
        <Button onClick={clearAndClose}>{t('dashboard.reportCancel')}</Button>
        <input onChange={handleChange} ref={hiddenFileInput} type="file" accept="image/*" style={{ display: 'none' }} />
      </DialogActions>
    </Dialog>
  );
};

export default ScannerModal;
