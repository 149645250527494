import { useTranslation } from 'react-i18next';

export default function FAQ() {

    const { t } = useTranslation();

    return (
        <div>
    
    <h3>
        {t('FAQ.FAQ1Title')}
    </h3>
    <p>
        {t('FAQ.FAQ1Desc')}
    </p>
    <h3>
        {t('FAQ.FAQ2Title')}
    </h3>
    <p>
        {t('FAQ.FAQ2Desc')}
    </p>

    <h3>
        {t('FAQ.FAQ3Title')}
    </h3>
    <p>
        {t('FAQ.FAQ3Desc')}
    </p>
    <h3>
        {t('FAQ.FAQ4Title')}
    </h3>
    <p>
        {t('FAQ.FAQ4Desc')}
    </p>

</div>


    )
} 