import { RedirectToCheckoutOptions, Stripe, loadStripe } from '@stripe/stripe-js';
import { useApiClient } from 'apiClient';
import { useSelector } from 'react-redux';

export const useStripe = () => {
  let stripePromse: Promise<Stripe | null>;
  const uid = useSelector((state: any) => state?.account?.uid);
  const { createStripeSession } = useApiClient();
  const email = useSelector((state: any) => state?.account?.email);
  const token = useSelector((state: any) => state?.account?.token);
  const getStripe = async () => {
    if (!stripePromse) {
      stripePromse = loadStripe(
        process.env.REACT_APP_IS_PROD === 'true'
          ? process.env.REACT_APP_STRIPE_LIVE_KEY || ''
          : process.env.REACT_APP_STRIPE_TEST_KEY || ''
      );
    }
    return stripePromse;
  };
  const item = {
    price:
      process.env.REACT_APP_IS_PROD === 'true'
        ? process.env.REACT_APP_STRIPE_LIVE_PRODUCT || ''
        : process.env.REACT_APP_STRIPE_TEST_PRODUCT || '',
    quantity: 1,
  };

  const checkoutOptions: RedirectToCheckoutOptions = {
    lineItems: [item],
    mode: 'payment',
    successUrl: `${location.origin}/thank-you`,
    cancelUrl: `${location.origin}`,
    // clientReferenceId: uid,
    // customerEmail: email,
  };
  const redirectToCheckout = async () => {
    let session = null;
    // await createStripeSession().then((res) => {
    //   session = res.data;
    // });
    const stripe = await getStripe();
    console.log('session', session);
    if (stripe) {
      const { error } = await stripe.redirectToCheckout(checkoutOptions);
    }
  };
  return {
    redirectToCheckout,
  };
};
