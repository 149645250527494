import Logo from './logo'
import { useState, useRef, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import PrivacyPolicy from '../privacyPolicy';
//import TermsOfUse from '../termsOfUse';
import { useTranslation } from 'react-i18next';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import FAQ from '../faq';
import Support from '../support';
import About from '../about';
import Partners from '../partners';
import Millerr from '../miller';
import Guide from '../productGuide';


const SubscribeForm = ({ status, message, onValidated }) => {
  const { t } = useTranslation();
  let email;
  const submit = () => 
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

    

  return (
    <form onSubmit={(e) => { e.preventDefault(); submit(); }}>
      <div className="flex flex-wrap mb-4">
        <div className="w-full">
          <label className="block text-sm sr-only" htmlFor="newsletter">{t('footer.subscribePlaceholder')}</label>
          <div className="relative flex items-center max-w-xs">
            <input 
              ref={node => (email = node)}
              id="newsletter" 
              type="email" 
              className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm" 
              placeholder={t('footer.subscribePlaceholder')} 
              required 
            />
            <button type="submit" className="absolute inset-0 left-auto" aria-label="Subscribe">
            <svg className="w-3 h-3 fill-current text-blue-600 mx-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                      </svg>
            </button>
          </div>
          {status === "sending" && <div>Sending...</div>}
          {status === "error" && <div dangerouslySetInnerHTML={{ __html: message }} />}
          {status === "success" && <div>Subscribed!</div>}
        </div>
      </div>
    </form>
  );
};




export default function Footer() {

  const [iconTransform, setIconTransform] = useState('scale(.05)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 640) { // Adjust this value based on your mobile breakpoint
        setIconTransform('scale(0.45)'); // Smaller scale for mobile
      } else {
        setIconTransform('scale(0.5)'); // Default scale
      }
    };

    // Set initial transform
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [iconTransform2, setIconTransform2] = useState('scale(0.5)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 640) { // Adjust this value based on your mobile breakpoint
        setIconTransform('scale(0.5)'); // Smaller scale for mobile
      } else {
        setIconTransform('scale(0.5)'); // Default scale
      }
    };

    // Set initial transform
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const url = "https://immpact-as.us21.list-manage.com/subscribe/post?u=c65f997e9029e7c9ae0f11ba9&amp;id=c505b3a16b&amp;f_id=0085f7e6f0";

  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isFAQModalOpen, setIsFAQModalOpen] = useState(false);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isPartnersModalOpen, setIsPartnersModalOpen] = useState(false);
  const [isMiller, setIsMiller] = useState(false);
  const [isGuideModalOpen, setIsGuideModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openFAQModal = () => {
    setIsFAQModalOpen(true);
  };

  const closeFAQModal = () => {
    setIsFAQModalOpen(false);
  };

  const openSupportModal = () => {
    setIsSupportModalOpen(true);
  };

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
  };

  const openAboutModal = () => {
    setIsAboutModalOpen(true);
  };

  const closeAboutModal = () => {
    setIsAboutModalOpen(false);
  };

  const openPartnersModal = () => {
    setIsPartnersModalOpen(true);
  };

  const closePartnersModal = () => {
    setIsPartnersModalOpen(false);
  };

  const openMiller = () => {
    setIsMiller(true);
  };

  const closeMiller = () => {
    setIsMiller(false);
  };

  const openGuideModal = () => {
    setIsGuideModalOpen(true);
  };

  const closeGuideModal = () => {
    setIsGuideModalOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800, 
    height: 500,
    maxHeight: '90%', 
    maxWidth: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #3b82f6', // Updated the color to blue
    boxShadow: 24,
    p: 4,
    borderRadius: '10px', // Added this to round the corners of the modal
    overflow: 'auto'
  };

  const styleSmall = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300, 
    height: 160,
    maxHeight: '90%', 
    maxWidth: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #3b82f6', // Updated the color to blue
    boxShadow: 24,
    p: 4,
    borderRadius: '10px', // Added this to round the corners of the modal
    overflow: 'auto'
  };

  const styleMed = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500, 
    height: 400,
    maxHeight: '90%', 
    maxWidth: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #3b82f6', // Updated the color to blue
    boxShadow: 24,
    p: 4,
    borderRadius: '10px', // Added this to round the corners of the modal
    overflow: 'auto'
  };


  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">

          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-3">
      <div className="mb-2">
        <Logo />
      </div>
      <div className="text-sm text-gray-600">
        <a
         
          className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out cursor-pointer"
          onClick={openModal2}
        >
          ·{" "} <u>{t('footer.termsOfUsePrivacyPolicy')}</u>
        </a>
        
      </div>

      

      <Modal
        open={isModalOpen2}
        onClose={closeModal2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 id="modal-modal-title">{t('footer.modalTitle')}</h2>
          <PrivacyPolicy />
        </Box>
      </Modal>
    </div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="tw-h6 text-gray-800 font-medium mb-2">{t('footer.productsHeading')}</h6>
            <ul className="tw-ul text-sm">
              <div>
              <li className="mb-2">
                <a onClick={openSupportModal} className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out cursor-pointer"> <u>{t('footer.requestDemo')}</u></a>
                <Modal
                  open={isSupportModalOpen}
                  onClose={closeSupportModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                <Box sx={styleSmall}>
                  
                  <Support />
                </Box>
              </Modal>
              </li>
              </div>
              <li className="mb-2">
                <a href="/login" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">{t('footer.pricing')} </a>
              </li>
              <div>
              <li className="mb-2">
                <a onClick={openSupportModal} className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out cursor-pointer"><u>{t('footer.tailoredRequests')}</u></a>
              </li>
              <Modal
                  open={isSupportModalOpen}
                  onClose={closeSupportModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                <Box sx={styleSmall}>
                  
                  <Support />
                </Box>
              </Modal>
              </div>
              <div>
              <li className="mb-2">
                <a onClick={openGuideModal} className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out cursor-pointer"> <u>User Guide</u></a>
                <Modal
                  open={isGuideModalOpen}
                  onClose={closeGuideModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                  
                  <Guide />
                </Box>
              </Modal>
              </li>
              </div>
            </ul>
            
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="tw-h6 text-gray-800 font-medium mb-2">{t('footer.resourcesHeading')}</h6>
            <ul className="tw-ul text-sm">

            <div>
              <li className="mb-2">
                <a onClick={openAboutModal} className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out cursor-pointer"><u>{t('footer.aboutImmpact')}</u></a>
              </li>
              <Modal
                  open={isAboutModalOpen}
                  onClose={closeAboutModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                  <About />
                </Box>
              </Modal>
            </div>

              <div>
              <li className="mb-2">
                <a onClick={openFAQModal} className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out cursor-pointer"> <u>{t('footer.faq')}</u></a>
              </li>
              <Modal
        open={isFAQModalOpen}
        onClose={closeFAQModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 id="modal-modal-title">{t('footer.faq')}</h2>
          <FAQ />
        </Box>
      </Modal>
              </div>
              <li className="mb-2">
                <a onClick={openSupportModal} className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out cursor-pointer"><u>{t('footer.feedback')}</u></a>
                <Modal
                  open={isSupportModalOpen}
                  onClose={closeSupportModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                <Box sx={styleSmall}>
                  
                  <Support />
                </Box>
              </Modal>
              </li>

              <div>
              <li className="mb-2">
                <a onClick={openSupportModal} className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out cursor-pointer"><u>{t('footer.support')}</u></a>
              </li>
              <Modal
                  open={isSupportModalOpen}
                  onClose={closeSupportModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                <Box sx={styleSmall}>
                  
                  <Support />
                </Box>
              </Modal>
              </div>

              <li className="mb-2">
                <a onClick={openPartnersModal} href="#0" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out cursor-pointer"><u>{t('footer.partners')}</u></a>
              </li>
              <Modal
                  open={isPartnersModalOpen}
                  onClose={closePartnersModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                  
                  <Partners />
                </Box>
              </Modal>
            </ul>
          </div>

          {/* 4th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="tw-h6 text-gray-800 font-medium mb-2">{t('footer.jumpToHeading')}</h6>
            <ul className="tw-ul text-sm">
              <li className="mb-2">
                <a href="/" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">{t('footer.home')}</a>
              </li>
              <li className="mb-2">
                <a href="#features" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">{t('footer.features')}</a>
              </li>
              <li className="mb-2">
                <a href="#upcoming-features" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">{t('footer.upcomingFeatures')}</a>
              </li>
              <li className="mb-2">
                <a href="#testimonials" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">{t('footer.testimonials')}</a>
              </li>
            </ul>
          </div>
          
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="tw-h6 text-gray-800 font-medium mb-2">{t('footer.community')}</h6>
            <ul className="tw-ul text-sm">
              <li className="mb-2">
                <a onClick={openMiller} className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out cursor-pointer"><u>{t('footer.miller')}</u></a>
              </li>
              <Modal
                  open={isMiller}
                  onClose={closeMiller}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                <Box sx={styleMed}>
                  
                  <Millerr />
                </Box>
              </Modal>
            </ul>
            
          </div>

          

          {/* 5th block */}
          

        </div>

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">

          {/* Social as */}
          <ul className="tw-ul flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <li>
              <a href="https://twitter.com/immpact_inc" className="flex justify-center items-center text-gray-600 hover:text-blue-600 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z" />
                </svg>
              </a>
            </li>
            <li className="ml-4">
              <a href="#0" className="flex justify-center items-center text-gray-600 hover:text-blue-600 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Github">
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                </svg>
              </a>
            </li>
            <li className="ml-4">
              <a href="#0" className="flex justify-center items-center text-gray-600 hover:text-blue-600 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                </svg>
              </a>
            </li>
            <li className="ml-4">
              <a href="https://www.instagram.com/immpact_inc/" className="flex justify-center items-center text-gray-600 hover:text-blue-600 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Instagram">
              <svg xmlns="http://www.w3.org/2000/svg" style={{ transform: iconTransform2 }} className="w-8 h-8 fill-current" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
              </a>
            </li>
            <li className="ml-4">
              <a href="#0" className="flex justify-center items-center text-gray-600 hover:text-blue-600 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Medium">
              <svg xmlns="http://www.w3.org/2000/svg" style={{ transform: iconTransform }} className="w-8 h-8 fill-current" viewBox="0 0 446 510"><path d="M22.7 33.4c13.5-4.1 28.1 1.1 35.9 12.9L224 294.3 389.4 46.2c7.8-11.7 22.4-17 35.9-12.9S448 49.9 448 64V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V169.7L250.6 369.8c-5.9 8.9-15.9 14.2-26.6 14.2s-20.7-5.3-26.6-14.2L64 169.7V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 49.9 9.2 37.5 22.7 33.4z"/></svg>
              </a>
            </li>
            <li className="ml-4">
              <a href="https://www.linkedin.com/company/immpact-automation-services-inc" className="flex justify-center items-center text-gray-600 hover:text-blue-600 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="LinkedIn">
              <svg xmlns="http://www.w3.org/2000/svg" style={{ transform: iconTransform }} className="w-8 h-8 fill-current" viewBox="0 0 446 510"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg>
              </a>
            </li>
          </ul>
          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4"> {t('footer.copyrights')}</div>

        </div>

      </div>
    </footer>
  )
}
