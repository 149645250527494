'use client'

import { useState, useRef, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import Image from "mui-image"
import FeaturesBg from '../images/features-bg.png'
import FeaturesBg2 from '../images/features-bg2.png'
import FeaturesElement from '../images/front.png'
import FeaturesElementFR from '../images/features-element-fr.png';
import FeaturesElementEN from '../images/features-element-en.png';
import Gif from '../images/gif.gif'
import aEN from '../images/1.png';
import bEN from '../images/2.png';
import cEN from '../images/3.png';
import dEN from '../images/4.png';
import eEN from '../images/5.png';
import fEN from '../images/6.png';
import aFR from '../images/1fr.png';
import bFR from '../images/2fr.png';
import cFR from '../images/3fr.png';
import dFR from '../images/4fr.png';
import eFR from '../images/5fr.png';
import fFR from '../images/6fr.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Fade } from "react-reveal";
import PrivacyPolicy from './privacyPolicy'
import { useTranslation } from 'react-i18next';

export default function Features() {

  const { t, i18n } = useTranslation();

  const isFrench = i18n.language === 'fr';

  const images = isFrench
    ? [aFR, bFR, cFR, dFR, eFR, fFR]
    : [aEN, bEN, cEN, dEN, eEN, fEN];

  const featuresElementImage = isFrench ? FeaturesElementFR : FeaturesElementEN;
  
  const [tab, setTab] = useState<number>(1)

  const tabs = useRef<HTMLDivElement>(null)

  const heightFix = () => {
    if (tabs.current && tabs.current.parentElement) tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`
  }

  useEffect(() => {
    heightFix()
  }, []) 

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800, 
    height: 500,
    maxHeight: '90%', 
    maxWidth: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #3b82f6', // Updated the color to blue
    boxShadow: 24,
    p: 4,
    borderRadius: '10px', // Added this to round the corners of the modal
    overflow: 'auto'
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update the width
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Adding event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean-up function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to determine image width based on window width
  const getImageWidth = () => {
    return windowWidth >= 768 ? 500 : 300; // 768px is a common breakpoint for desktop
  };

  const getImageWidthForFirstImage = () => {
    return windowWidth >= 768 ? 500 : 400; // 768px is a common breakpoint for desktop
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to update state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Check if window is defined (this check is important for server-side rendering)
    if (typeof window !== 'undefined') {
      // Set the initial value based on current window width
      handleResize();

      // Add event listener for subsequent updates
      window.addEventListener('resize', handleResize);

      // Cleanup event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const style1 = {
    marginTop: windowWidth < 768 ? '0px' : '350px',
  };

  const style2 = {
    marginTop: windowWidth < 768 ? '0px' : '275px',
  };

  const style3 = {
    marginTop: windowWidth < 768 ? '0px' : '275px',
  };

  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="tw-h1 h2 mb-4">{t('features.headerTitle')}</h1>
            <p className="text-xl text-gray-600">{t('features.headerSubtitle')}</p>
          </div>

          {/* Section content */}
          <div className="md:grid md:grid-cols-12 md:gap-6">

            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6" data-aos="fade-right">
              <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                <h3 className="tw-h3 h3 mb-3">{t('features.sectionTitle')}</h3>
                <p className="text-xl text-gray-600">{t('features.sectionDescription')}</p>
              </div>
              {/* Tabs buttons */}
              <div className="mb-8 md:mb-0">
                <a
                  className={`tw-a flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(1); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">{t('features.tab1Title')}</div>
                    <div className="text-gray-600">{t('features.tab1Description')}</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                    <FontAwesomeIcon icon={faWandMagicSparkles} className="text-blue-600" />
                  </div>
                </a>
                <a
                  className={`tw-a flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(2); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">{t('features.tab2Title')}</div>
                    <div className="text-gray-600">{t('features.tab2Description')}</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                    <FontAwesomeIcon icon={faClock} className="text-blue-600" />
                  </div>
                </a>
                <a
                  className={`tw-a flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(3); }}
                >
                  <div>
      <div className="font-bold leading-snug tracking-tight mb-1">{t('features.tab3Title')}</div>
      <div className="text-gray-600">
      {t('features.tab3Description')}.&nbsp; 
        <span 
            role="button"
            tabIndex={0}
            className="underline text-blue-600 hover:text-blue-800 cursor-pointer"
            onClick={handleOpen}
            onKeyDown={handleOpen}
        >
            {t('features.discoverMore')}
        </span>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 id="modal-modal-title">{t('features.privacyPolicyTitle')}</h2>
          <PrivacyPolicy />
        </Box>
      </Modal>
    </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <FontAwesomeIcon icon={faShieldAlt} className="text-blue-600" />
                  </div>
                </a>
              </div>
            </div>

            {/* Tabs items */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1">
              <div className="transition-all">
                <div className="relative flex flex-col text-center lg:text-right" data-aos="zoom-y-out" ref={tabs}>
                  {/* Item 1 */}
                  <Transition
                    show={tab === 1}
                    appear={true}
                    className="w-full"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterFrom="opacity-0 translate-y-16"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-16"
                    beforeEnter={() => heightFix()}
                    unmount={false}                     
                  >
                    <div className="relative inline-flex flex-col" style={style1}>
                    <Image className="md:max-w-none mx-auto rounded" src={FeaturesElement} width={getImageWidth()} height="44" alt="Element" style={{ top: '30%' }} />
                      <br></br>
                      <br></br>
                      {/*<Image className="md:max-w-none mx-auto rounded" src={FeaturesElement} width={getImageWidth()} height="44" alt="Element" style={{ top: '30%' }} />*/}
                    </div>
                  </Transition>
                  {/* Item 2 */}
                  <Transition
                    show={tab === 2}
                    appear={true}
                    className="w-full"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterFrom="opacity-0 translate-y-16"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-16"
                    beforeEnter={() => heightFix()}
                    unmount={false}                     
                  >
                    <div className="relative inline-flex flex-col" style={style3}>
                      <Image className="md:max-w-none mx-auto rounded" src={Gif} width={getImageWidth()} height="462" alt="Features bg" />
                      <br></br>
                      <Image className="md:max-w-none mx-auto rounded" src={featuresElementImage} width={getImageWidth()} height="44" alt="Element" style={{ top: '30%' }} />
                    </div>
                  </Transition>
                  {/* Item 3 */}
                  <Transition
  show={tab === 3}
  appear={true}
  className="w-full"
  enter="transition ease-in-out duration-700 transform order-first"
  enterFrom="opacity-0 translate-y-16"
  enterTo="opacity-100 translate-y-0"
  leave="transition ease-in-out duration-300 transform absolute"
  leaveFrom="opacity-100 translate-y-0"
  leaveTo="opacity-0 -translate-y-16"
  beforeEnter={() => heightFix()}
  unmount={false}                     
>

<div className="relative inline-flex flex-col" style={style2}>
                    <Fade right delay={300}>
                      <Image className="md:max-w-none absolute w-full left-0 transform animate-float" src={images[0]} width={getImageWidthForFirstImage()} height="44" alt="Element" style={{ top: '30%' }} />
                    </Fade>
                      <br></br>
                    <Fade right delay={600}>
                      <Image className="md:max-w-none absolute w-full left-0 transform animate-float" src={images[1]} width={getImageWidthForFirstImage()} height="44" alt="Element" style={{ top: '30%' }} />
                      <br></br>
                    </Fade>
                    <Fade right delay={900}>
                      <Image className="md:max-w-none absolute w-full left-0 transform animate-float" src={images[2]} width={getImageWidthForFirstImage()} height="44" alt="Element" style={{ top: '30%' }} />
                      <br></br>
                    </Fade>
                    <Fade right delay={1200}>
                      <Image className="md:max-w-none absolute w-full left-0 transform animate-float" src={images[3]} width={getImageWidthForFirstImage()} height="44" alt="Element" style={{ top: '30%' }} />
                      <br></br>
                    </Fade>
                    <Fade right delay={1500}>
                      <Image className="md:max-w-none absolute w-full left-0 transform animate-float" src={images[4]} width={getImageWidthForFirstImage()} height="44" alt="Element" style={{ top: '30%' }} />
                      <br></br>
                    </Fade>
                    <Fade right delay={1800}>
                      <Image className="md:max-w-none absolute w-full left-0 transform animate-float" src={images[5]} width={getImageWidthForFirstImage()} height="44" alt="Element" style={{ top: '30%' }} />
                      <br></br>
                    </Fade>
                    
                    </div>
                  </Transition>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  )
}