export default function Support() {
    return (
        <div style={{ textAlign: 'center' }}>  
            <h3>Email address:</h3>
            <p>
                info@immpact-as.ca
            </p>
        </div>
    )
}
