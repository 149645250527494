import { Stack, Card, Typography } from '@mui/material';
import { useApiClient } from 'apiClient';
import SelectModal from 'components/action-modals/SelectModal';
import Iconify, { icon } from 'components/iconify';
import Header from 'layouts/dashboard/header';
import React, { useState } from 'react';
import { ModalType } from 'types';
import { useStripe } from 'utils/hooks';
import immigrationRepresentativeImage from '../../src/landing/images/IC1.png';
import clientImage from '../../src/landing/images/client.png';
import { useTranslation } from 'react-i18next';

const AccountSetup = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<ModalType>();
  const { redirectToCheckout } = useStripe();

  const handleCloseModal = () => {
    setModalOpen(undefined);
  };

  return (
    <>
      <Header hasNav={false} />
      <SelectModal open={modalOpen} handleClose={handleCloseModal} />
      <div>
        <br></br>
      </div>
      <br></br>
      <Typography
        variant="h3"
        sx={{
          mt: { xs: 2, sm: '64px' }, // Adjust top margin for mobile and desktop
          mb: 4,
          width: '100%',
          textAlign: 'center',
          // Remove any unnecessary styles that may be affecting the layout
        }}
      >
        {t('accountSetup.question')}
      </Typography>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 2, sm: 3 }}
        justifyContent="center"
        alignItems="center"
        sx={{
          // Apply margin-top only on screens smaller than the 'sm' breakpoint
          mt: { xs: '100px', sm: 0 }, // Adjust '100px' as needed for mobile layout
          width: '100%',
          position: 'relative',
          zIndex: 2, // This should still be higher than the header's z-index
          // ... other styles...
        }}
      >
        <Card
          sx={{
            height: '25em',
            width: '25em',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            backgroundColor: '#fdf1e5',
          }}
          onClick={() => {
            redirectToCheckout();
          }}
        >
          <img src={immigrationRepresentativeImage} width="70%" height="70%" />
          <Typography variant="h6">{t('accountSetup.immigrationRepresentative')}</Typography>
        </Card>
        <Card
          sx={{
            height: '25em',
            width: '25em',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            backgroundColor: '#c8d6dd',
          }}
          onClick={() => {
            setModalOpen(ModalType.ADD_REP_CODE);
          }}
        >
          <img src={clientImage} width="70%" height="70%" />
          <Typography variant="h6">{t('accountSetup.applicant')}</Typography>
        </Card>
      </Stack>
    </>
  );
};

export default AccountSetup;
