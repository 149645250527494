import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setAccountType, setFirstName, setLastName } from 'redux/accountSlice';
import { sendMessage } from 'redux/alertSlice';
import { populateCurrentCase, populateCurrentUser, populateUsers, setCurrentUserId } from 'redux/clientSlice';
import {
  populateCurrentPage,
  populateDependants,
  populatePages,
  setCancelFunction,
  setIsCurrentPageLoading,
} from 'redux/formSlice';
import { checkEntry, logIn, logOut, toSetup } from 'redux/loginSlice';
import { AccountType, AlertSeverity, LoginStatus, PageMetadata, ReducerState } from 'types/types';
import { setReturnObject } from 'redux/returnSlice';
import fs from 'fs';

export const useApiClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {pageId=''} = useParams();
  const isCurrentPageLoading = useSelector((state: ReducerState) => state.form.isCurrentPageLoading);
  const currentPage = useSelector((state: ReducerState) => state.form.currentPage);
  const language = useSelector((state: ReducerState) => state.account.language);
  const caseId = useSelector((state: ReducerState) => state.client.currentCase);
  const token = useSelector((state: ReducerState) => state.account.token);
  const cancelFunction = useSelector((state: ReducerState) => state.form.cancelFunction);
  const email = useSelector((state: ReducerState) => state.account.email);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = process.env.REACT_APP_IMM_API_URL || 'http://localhost:5000';

  const getAllUsers = async () => {
    await axios
      .get(`${url}/Rep/getAllUsers`, config)
      .then((res) => {
        dispatch(populateUsers(res.data));
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: err.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const getallCases = async (userId: string) => {
    await axios
      .get(`${url}/Rep/getAllCases/${userId}`, config)
      .then((res) => {
        dispatch(populateCurrentUser(res.data));
        dispatch(setCurrentUserId(userId));
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: err.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const createCase = async (userId: string, caseName: string, caseType: string) => {
    const getRandomId = () => {
      const timestamp = new Date().getTime();
      const string36 = timestamp.toString(36).toUpperCase();
      return string36;
    };
    await axios
      .post(
        `${url}/Rep/createCase/${userId}`,
        {
          data: {
            caseName,
            caseType,
            userData: {
              [getRandomId()]: {
                formType: {
                  value: 'MainApplicant',
                },
              },
            },
          },
          //TODO: add memebers, main applicant as dependent
        },
        config
      )
      .then(async (res) => {
        await getallCases(userId);
        dispatch(
          sendMessage({
            open: true,
            message: `Case with name: ${caseName} successfully created`,
            severity: AlertSeverity.SUCCESS,
          })
        );
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: err.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const associateUser = async (repId: string, userId: string) => {
    await axios
      .post(
        `${url}/Rep/associateUser/${repId}/${userId}`,
        {
          email,
        },
        config
      )
      .then((res) => {
        dispatch(logIn());
        dispatch(setAccountType(AccountType.USER));
        dispatch(
          sendMessage({
            open: true,
            message: `User associated successfully`,
            severity: AlertSeverity.SUCCESS,
          })
        );
        getAllUsers();
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Error associating user`,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const createUser = async (repId: string = '', givenName: string, lastName: string, email: string) => {
    await axios
      .post(
        `${url}/Rep/createUser/${repId}`,
        {
          data: {
            givenName,
            lastName,
            email,
          },
        },
        config
      )
      .then((res) => {
        dispatch(
          sendMessage({
            open: true,
            message: `successfully created user with id: ${res.data.userId}`,
            severity: AlertSeverity.SUCCESS,
          })
        );
        getAllUsers();
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: err.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  // Provide access token instead
  const createRep = async () => {
    await axios
      .post(`${url}/Rep/CreateRep`, { data: {} }, config)
      .then(() => {
        dispatch(logIn());
        dispatch(setAccountType(AccountType.REP));
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: err.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const getCasePages = async (dependentId: string) => {
    await axios
      .get(`${url}/Page/getPages/${language}/${caseId}/${dependentId}`)
      .then((res) => {
        let filteredPages = res.data?.map?.((page: PageMetadata) => {
          return {
            id: `forms/${page.id}/${dependentId}`,
            name: page.name[language],
          };
        });
        dispatch(populatePages(filteredPages));
        dispatch(populateCurrentCase(caseId));
        
        // find the page in fildtered pages where id includes pageID 
        const page = filteredPages.find((page: { id: string | string[]; }) => page.id.includes(pageId));

        navigate(`../${page.id || filteredPages[0]?.id}`);
        console.log(window.location);
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: err.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const getIPR = async (caseId: string) => {
    return await axios
      .get(`${url}/Rep/getCase/IPR/${caseId}`, config)
      .then(async (res) => {
        return await res.data;
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: err.message,
            severity: AlertSeverity.ERROR,
          })
        );
        return [];
      });
  };

  const getCurrentPage = async (pageId: string, dependentId: string) => {
    if (cancelFunction) {
      cancelFunction('Request cancelled by user');
      dispatch(setCancelFunction(null));
    }
    const { token, cancel } = axios.CancelToken.source();
    dispatch(setCancelFunction(cancel));
    dispatch(setIsCurrentPageLoading(true));
    await axios
      .get(`${url}/Page/getPage/${language}/${pageId}/${caseId}/${dependentId}`, {
        cancelToken: token,
        ...config,
      })
      .then((res) => {
        dispatch(setIsCurrentPageLoading(false));
        dispatch(populateCurrentPage(res.data));
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('helllo');
        } else {
          dispatch(
            sendMessage({
              open: true,
              message: err.message,
              severity: AlertSeverity.ERROR,
            })
          );
        }
      });
  };

  const atEntry = async (accessToken: string, data: {} | null = null) => {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    await axios
      .post(`${url}/Rep/atEntry`, { data }, config)
      .then((res) => {
        dispatch(setFirstName(res?.data?.givenName));
        dispatch(setLastName(res?.data?.lastName));
        dispatch(setAccountType(res?.data?.type));
        if (res?.data?.status == LoginStatus.NOT_SETUP) {
          dispatch(toSetup());
        } else if (res?.data?.status == LoginStatus.FULLY_SETUP) {
          dispatch(logIn());
        }
        dispatch(checkEntry());
      })
      .catch((error) => {
        dispatch(
          sendMessage({
            open: true,
            message: error.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const createRepCode = async () => {
    return await axios
      .get(`${url}/Rep/createRepCode`, config)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        dispatch(
          sendMessage({
            open: true,
            message: error.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const savePage = async (pageId: string) => {
    // TOOD: this saves everything not just the current page
    const returnables = Object.keys(sessionStorage).filter((o) => o.startsWith(pageId + '/' + caseId + '/return'));
    let returnObject = {};
    returnables.forEach((returnable) => {
      let currentLevel: Record<string, any> = returnObject;
      let returnArr = returnable.split('/');
      returnArr = returnArr.slice(3, returnArr.length);
      for (const element of returnArr) {
        if (returnArr?.indexOf?.(element) === returnArr.length - 1) {
          currentLevel[element] = JSON.parse(sessionStorage.getItem(returnable) || '{}');
        }
        if (!currentLevel[element]) {
          currentLevel[element] = {};
        }
        currentLevel = currentLevel[element];
      }
    });

    await axios
      .post(
        `${url}/Rep/editCase/${caseId}`,
        {
          data: { userData: returnObject, language },
        },
        config
      )
      .then(async (res) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Page Saved Successfully`,
            severity: AlertSeverity.SUCCESS,
          })
        );
        dispatch(setReturnObject({}));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          dispatch(
            sendMessage({
              open: true,
              message: `Session expired, please log in again`,
              severity: AlertSeverity.ERROR,
            })
          );
          dispatch(logOut());
        }
        dispatch(
          sendMessage({
            open: true,
            message: err.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const getDependents = async () => {
    await axios
      .get(`${url}/Page/getDependents/${caseId}`, config)
      .then((res) => {
        dispatch(populateDependants(res.data));
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: err.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const removeDependent = async (dependentId: string) => {
    await axios
      .delete(`${url}/Page/removeDependent/${caseId}/${dependentId}`, config)
      .then((res) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Dependent removed successfully`,
            severity: AlertSeverity.SUCCESS,
          })
        );
        getDependents();
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Error removing dependent`,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const reportProblem = async (title: string, description: string) => {
    await axios
      .post(`${url}/Rep/reportProblem`, { data: { title, description } }, config)
      .then((res) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Problem reported successfully`,
            severity: AlertSeverity.SUCCESS,
          })
        );
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Error reporting problem`,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const deleteAccount = async () => {
    await axios
      .delete(`${url}/Rep/deleteAccount`, config)
      .then((res) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Account deleted successfully`,
            severity: AlertSeverity.SUCCESS,
          })
        );
        dispatch(logOut());
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Error deleting account`,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const deleteClient = async (repId: string, clientId: string) => {
    await axios
      .delete(`${url}/Rep/deleteUser/${repId}/${clientId}`, config)
      .then((res) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Client deleted successfully`,
            severity: AlertSeverity.SUCCESS,
          })
        );
        getAllUsers();
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Error deleting client`,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const deleteCase = async (userId: string, caseId: string) => {
    await axios
      .delete(`${url}/Rep/deleteCase/${userId}/${caseId}`, config)
      .then((res) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Case deleted successfully`,
            severity: AlertSeverity.SUCCESS,
          })
        );
        getallCases(userId);
      })
      .catch((err) => {
        dispatch(
          sendMessage({
            open: true,
            message: `Error deleting case`,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const getCase = async (caseId: string) => {
    return await axios.get(`${url}/Rep/getCase/${caseId}`, config).catch((err) => {
      dispatch(
        sendMessage({
          open: true,
          message: `Error getting case`,
          severity: AlertSeverity.ERROR,
        })
      );
    });
  };

  const createStripeSession = async () => {
    return await axios.post(`${url}/Rep/create-checkout-session`, {}, config).catch((err) => {
      dispatch(
        sendMessage({
          open: true,
          message: err.message,
          severity: AlertSeverity.ERROR,
        })
      );
    });
  };

  const scanPassport = async (imageFile: Blob) => {
    const ocr_url =
      process.env.REACT_APP_OCR_URL ||
      'https://ocr-extractor-immpact-dc160f527cfc.herokuapp.com/extract-passport-info/';
    // Replace 'path/to/your/image.jpg' with the actual path to your image file
    const imagePath = '/Users/shadyguindi/Documents/Software Projects/immigrate-frontend/passport3.webp';

    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    if (!allowedTypes.includes(imageFile.type)) {
      throw new Error('Invalid file type. Please upload a valid image.');
    }

    const formData = new FormData();

    // Create a FormData object to send the file
    formData.append('file', imageFile);
    return await axios.post(`${ocr_url}`, formData, config).catch((err) => {});
  };

  return {
    scanPassport,
    createCase,
    associateUser,
    getAllUsers,
    getCasePages,
    getCurrentPage,
    createStripeSession,
    getallCases,
    getIPR,
    getCase,
    createUser,
    createRep,
    createRepCode,
    atEntry,
    savePage,
    getDependents,
    removeDependent,
    reportProblem,
    deleteAccount,
    deleteCase,
    deleteClient,
  };
};
