import { createSlice } from "@reduxjs/toolkit";

const accountSlice = createSlice({
    name: 'account',
    initialState: {
        uid: undefined, 
        value: undefined,
        token: undefined,
        repId: undefined,
        name: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        isRep: false,
        accountType: undefined,
        language: 'en',
    },
    reducers: {
        setUid: (state, action) =>{
            state.uid = action.payload
        },
        setAccount: (state, action) => {
            state = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setAccessToken: (state, action) => {
            state.token = action.payload
        },
        setRepId: (state, action) => {
            state.repId = action.payload
        },
        setFirstName: (state, action) => {
            state.firstName = action.payload
        },
        setLastName: (state, action) => {
            state.lastName = action.payload
        },
        setAccountType: (state, action) => {
            state.accountType = action.payload
        }, 
        setLanguage: (state, action) => {   
            state.language = action.payload
        }
    }
})

export const { setAccount, setAccessToken, setRepId, setFirstName, setLastName, setAccountType, setUid, setLanguage, setEmail } = accountSlice.actions
export default accountSlice