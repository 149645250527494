import React from 'react'

const News = () => {
  return (
    <div>
        <iframe src="https://www.cicnews.com/" title="News" width="100%" height="1000px"/>
    </div>
  )
}

export default News