import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Modal,
  OutlinedInput,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useApiClient } from 'apiClient';
import { useTranslation } from 'react-i18next';
import { StandardInput } from 'components/form-input';
import Iconify from 'components/iconify';
import { AstPath } from 'prettier';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { customStyles } from 'components/form-input';

interface AddUserModalProps {
  open: boolean;
  handleClose: () => void;
}

const AddCaseModal = ({ open, handleClose }: AddUserModalProps) => {
  const { t } = useTranslation();
  const { createCase } = useApiClient();
  const [caseName, setCaseName] = useState('');
  const [caseType, setCaseType] = useState('');
  const { userId = '' } = useParams();
  const [d1State, setD1State] = useState<boolean>(false);
  const [d2State, setD2State] = useState<boolean>(false);
  const handleNewCase = async () => {
    createCase(userId, caseName, caseType);
    handleClose();
  };
  const resetAndClose = () => {
    setD1State(false);
    setD2State(false);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={resetAndClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: '5%', marginLeft: '2%' }}>{t('dashboard.createNewCase')}</DialogTitle>
      <DialogContent sx={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <DialogContentText sx={{ marginBottom: '5%', marginLeft: '2%' }}>
          {t('dashboard.createNewCaseDesc')}
        </DialogContentText>
        <StandardInput
          placeholder={t('dashboard.caseNameDot')}
          onChange={(e) => {
            setCaseName(e?.target?.value);
            setD1State(true);
          }}
        />
        {/* <Typography>Case Type</Typography> */}
        <ReactSelect
          menuPortalTarget={document.body}
          styles={customStyles}
          options={[
            {
              label: 'Agri-Food Pilot',
              value: 'AFP',
            },
            {
              label: 'Rural and Northern Immigration Program',
              value: 'RNIP',
            },
            {
              label: 'Provincial Nominee Program (PNP)',
              value: 'PNP',
            },
            {
              label: 'Self-Employed Persons Class',
              value: 'SEPC',
            },
            {
              label: 'Start-Up Business Class',
              value: 'SUBC',
            },
            {
              label: 'Quebec Skilled Workers Program',
              value: 'QSWP',
            },
            {
              label: 'Quebec Selected Investor Program',
              value: 'QSIP',
            },
            {
              label: 'Quebec Entrepreneur Program',
              value: 'QEP',
            },
            {
              label: 'Quebec Self-Employed Persons Program',
              value: 'QSEPP',
            },
            {
              label: 'Atlantic Immigration Program',
              value: 'AIP',
            },
            {
              label: 'Home Child Care Provider Pilot: Direct to permanent residence category',
              value: 'HCCPP-DPR',
            },
            {
              label: 'Home Support Worker Pilot: Gaining experience category',
              value: 'HSWP-GE',
            },
            {
              label: 'Home Support Worker Pilot: Direct to permanent residence category',
              value: 'HSWP-DPR',
            },
            {
              label: 'Economic Mobility Pathways Pilot - Atlantic Immigration Program',
              value: 'EMPP-AIP',
            },
            {
              label: 'Economic Mobility Pathways Pilot - Provincial Nominee Program (PNP)',
              value: 'EMPP-PNP',
            },
            {
              label: 'Economic Mobility Pathways Pilot - Rural and Northern Immigration Program',
              value: 'EMPP-RNIP',
            },
            {
              label: 'Federal Economic Mobility Pathways Pilot',
              value: 'FEMPP',
            },
            {
              label: 'Spouse: Spouse or common-law partner in Canada class',
              value: 'SCLPC',
            },
            {
              label: 'Spouse: Family class (outside Canada)',
              value: 'SFC',
            },
            {
              label: 'Common-law Partner: Spouse or common-law partner in Canada class',
              value: 'CLPC',
            },
            {
              label: 'Common-law Partner: Family class (outside Canada)',
              value: 'CLFC',
            },
            {
              label: 'Conjugal Partner',
              value: 'CP',
            },
            {
              label: 'Dependent Child',
              value: 'DC',
            },
            {
              label: 'Other Relative',
              value: 'OR',
            },
            {
              label: 'Adopted child/Child to be adopted in Canada',
              value: 'ACC',
            },
            {
              label: 'Orphaned sibling/nephew/niece/grandchild',
              value: 'OSNG',
            },
            {
              label: 'Refugee: Government Assisted Refugees Program',
              value: 'GARP',
            },
            {
              label: 'Refugee: The Private Sponsorship of Refugees (PSR) program (Community Sponsor [CS])',
              value: 'PSR-CS',
            },
            {
              label: 'Refugee: The Private Sponsorship of Refugees (PSR) program (Group of Five [G5])',
              value: 'PSR-G5',
            },
            {
              label: 'Refugee: The Private Sponsorship of Refugees (PSR) program (Quebec Refugee Sponsor [Spokesperson])',
              value: 'PSR-QRS',
            },
            {
              label: 'Refugee: The Private Sponsorship of Refugees (PSR) program (Sponsorship Agreement Holder)',
              value: 'PSR-SAH',
            },
            {
              label: 'Refugee: In Canada - Protected Person',
              value: 'RPP',
            },
            {
              label: 'Other: In Canada - Humanitarian and Compassionate Considerations',
              value: 'HCC',
            },
            {
              label: 'Other: FV - In - Canada - Humanitarian and Compassionate Considerations',
              value: 'FVHCC',
            },
            {
              label: 'Other: Permit Holder Class',
              value: 'PHC',
            },
            {
              label: 'Other: Permanent residence pathways for Hong Kong residents',
              value: 'PRPHKR',
            },
            {
              label: 'Other: Ukraine Family Reunification Public Policy',
              value: 'UFRRPP',
            },
            {
              label: 'Other: Ukraine Family Reunification Public Policy QC',
              value: 'UFRRPPQC',
            }
          ]}
          onChange={(selection) => {
            setD2State(true);
            setCaseType(selection?.value || '');
          }}
          minMenuHeight={200}
          maxMenuHeight={200}
          placeholder="Select Case Type"
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={!(d1State && d2State)} onClick={handleNewCase}>
          Add Case
        </Button>
        <Button onClick={resetAndClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCaseModal;
