import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../utils/hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm, SignupForm } from '../components/login';
import { appName } from 'utils/globals';
import { useLogin } from 'apiClient';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------



const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { t } = useTranslation();
  const mdUp = useResponsive('up', 'md');
  const { googleLogin } = useLogin();
  const [isSignUp, setIsSignUp] = useState(true);

  return (
    <>
      <Helmet>
        <title> Login | Immpact </title>
      </Helmet>

      <StyledRoot>
        

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            {t('login.welcomeBack')}
            </Typography>
            <img src="/assets/illustrations/login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent sx={isSignUp && { marginTop: 0, paddingTop: 5 }}>
            <Typography variant="h4" gutterBottom>
            {isSignUp ? t('login.signupTitle') : t('login.loginTitle')}

            </Typography>
            {/* SIGN-UP */}
            <Typography variant="body2" sx={{ mb: 5 }}>
            {t('login.noAccount')} {''}
              <Link
                variant="subtitle2"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsSignUp(!isSignUp);
                }}
              >
                {isSignUp ? t('login.signIn') : t('login.getStarted')}

              </Link>
            </Typography>

            {/* <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack> */}

            {isSignUp ? <SignupForm /> : <LoginForm />}
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('login.or')}
              </Typography>
            </Divider>
            <Button fullWidth size="large" color="inherit" variant="outlined" onClick={googleLogin}>
              <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
            </Button>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
