import { createSlice } from "@reduxjs/toolkit";
import { User } from "types/types";

const clientSlice = createSlice({
    name: 'client',
    initialState: {
        value: [] as User[],
        currentCase: "" as string,
        currentUser: [], // stores cases of the current user
        currentUserId: "" as string,
    },
    reducers: {
        populateUsers: (state, action) => {
            state.value = action.payload
        },
        populateCurrentCase: (state, action) => {
            state.currentCase = action.payload
        },
        populateCurrentUser: (state, action) => {
            state.currentUser = action.payload
        },
        setCurrentUserId: (state, action) => {
            state.currentUserId = action.payload
        },
        clearUsers: (state, action) => {
            state.value = [] as User[]
        }

    }
})

export const { populateUsers, clearUsers, populateCurrentCase, populateCurrentUser , setCurrentUserId} = clientSlice.actions;
export default clientSlice;