import React from 'react';
import { ModalType } from 'types';
import AddUserModal from './AddUserModal';
import DeleteUserModal from './DeleteUserModal';
import UpdateUserModal from './UpdateUserModal';
import AddRepCodeModal from './AddRepCodeModal';
import AddCaseModal from './AddCaseModal';
import DeleteCaseModal from './DeleteCaseModal';
import AddDependentModal from './AddDependentModal';
import ReportProblemModal from './ReportProblemModal';
import AutomationModal from 'landing/components/automationModal';
import RepIdModal from './RepIdModal';
import ScannerModal from './ScannerModal';


interface SelectModalProps {
  open: ModalType;
  handleClose: () => void;
  args?: any;
}

const SelectModal = ({ open, handleClose, args = {} }: SelectModalProps) => {
  return (
    <>
      <AddDependentModal open={open===ModalType.ADD_DEPENDENT} handleClose={handleClose} isEdit={args?.isEdit} editId={args?.editId}/>
      <AddUserModal open={open === ModalType.NEW_USER} handleClose={handleClose} />
      <UpdateUserModal open={open === ModalType.UPDATE_USER} handleClose={handleClose} />
      <DeleteUserModal open={open === ModalType.DELETE_USER} handleClose={handleClose} selectedClient={args?.id} />
      <AddRepCodeModal open={open === ModalType.ADD_REP_CODE} handleClose={handleClose} />
      <AddCaseModal open={open === ModalType.ADD_CASE} handleClose={handleClose} />
      <DeleteCaseModal open={open === ModalType.DELETE_CASE} handleClose={handleClose} caseId={args?.caseId} clientId={args?.clientId}/>
      <ReportProblemModal open={open === ModalType.REPORT_PROBLEM} handleClose={handleClose}/>
      <AutomationModal open={open === ModalType.AUTOMATE} handleClose={handleClose} caseId={args?.caseId}/>
      <RepIdModal open={open === ModalType.REP_ID} handleClose={handleClose}/>
      <ScannerModal open={open === ModalType.SELECT_DOC_SCANNER} handleClose={handleClose} />
    </>
  );
};

export default SelectModal;
