import { useState } from "react";
import { useSelector } from "react-redux";
import useAvatar from "utils/hooks/useAvatar";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
import Iconify from "components/iconify";
// mocks_
import account from "../../../_mock/account";
import { useLogin } from "apiClient";
import SelectModal from "components/action-modals/SelectModal";
import { ModalType } from "types";
import { useTranslation } from "react-i18next";
import Guide from "landing/components/productGuide";
import Modal from "@mui/material/Modal";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(null);
  const [reportProblem, setReportProblem] = useState("");
  const [repId, setRepId] = useState("");
  const accountInfo = useSelector((state: ReducerState) => state?.account);
  const { stringAvatar } = useAvatar();
  const { logout } = useLogin();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 500,
    maxHeight: "90%",
    maxWidth: "90%",
    bgcolor: "background.paper",
    border: "2px solid #3b82f6", // Updated the color to blue
    boxShadow: 24,
    p: 4,
    borderRadius: "10px", // Added this to round the corners of the modal
    overflow: "auto",
  };

  return (
    <>
      <SelectModal
        open={reportProblem}
        handleClose={() => setReportProblem("")}
      />
      <SelectModal open={repId} handleClose={() => setRepId("")} />
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          {...stringAvatar(
            accountInfo?.firstName + " " + accountInfo?.lastName,
          )}
        />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            maxWidth: 300,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {accountInfo.firstName} {accountInfo.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {accountInfo.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <MenuItem
          onClick={() => {
            handleClose();
            setReportProblem(ModalType.REPORT_PROBLEM);
            // logout();
          }}
          sx={{ m: 1, marginLeft: 2, paddingLeft: 0 }}
        >
          <Iconify
            sx={{ marginRight: 1, paddingLeft: 0 }}
            icon="octicon:report-16"
          />
          {t("dashboard.report")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setRepId(ModalType.REP_ID);
            // logout();
          }}
          sx={{ m: 1, marginLeft: 2, paddingLeft: 0 }}
        >
          <Iconify
            sx={{ marginRight: 1, paddingLeft: 0 }}
            icon="teenyicons:id-outline"
          />
          {t("repId.popOver")}
        </MenuItem>

        <MenuItem
          onClick={openModal}
          sx={{ m: 1, marginLeft: 2, paddingLeft: 0 }}
        >
          <Iconify sx={{ marginRight: 1, paddingLeft: 0 }} icon="ep:guide" />{" "}
          {/* Assuming 'description' is the icon you want for the User Guide */}
          User Guide
        </MenuItem>

        <Modal
          open={isModalOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Guide />
          </Box>
        </Modal>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={() => {
            handleClose();
            logout();
          }}
          sx={{ m: 1, marginLeft: 2, paddingLeft: 0 }}
        >
          <Iconify
            sx={{ marginRight: 1, paddingLeft: 0 }}
            icon="material-symbols:logout"
          />
          {t("dashboard.logout")}
        </MenuItem>
      </Popover>
    </>
  );
}
