import React, { useState, useEffect } from 'react';
import Image from 'mui-image';
//import TestimonialImage from '../images/testimonial.jpg';
import TestimonialImage2 from '../images/testimonial2.jpg';
//import TestimonialImage3 from '../images/testimonial3.jpg';
import { Fade } from "react-reveal";
import { useTranslation } from 'react-i18next';


export default function Testimonials() {

  const { t } = useTranslation();


  
  const testimonials = [


    /*
    {
      quote: "I love this product and would recommend it to anyone. Could not be easier to use, and our multiple websites are wonderful. We get nice comments all the time.",
      author: "Stanislav Belevici",
      position: "Chair of the RCIC",
      company: "@board-of-directors",
      url: "https://college-ic.ca/about-the-college/board-of-directors",
    },
    */
    {
      quote: t('testimonials.testimonial1.quote'),
      author: t('testimonials.testimonial1.author'),
      position: t('testimonials.testimonial1.position'),
      company: t('testimonials.testimonial1.company'),
      url: "https://ip-immigration.com", // URLs typically don't need translation
    }    
    /*
    {
      quote: "I love this product and would recommend it to anyone. Could not be easier to use, and our multiple websites are wonderful. We get nice comments all the time.",
      author: "Arian Sultafa",
      position: "RCIC-IRB, Bsc., LL.B., Adjunct Professor",
      company: "@YongeImmigration",
      url: "https://www.yongeimmigration.com/",
    },
    */
  ];



const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  const showNextTestimonial = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const timeout = setTimeout(showNextTestimonial, 5000); // 5000ms = 5s
    return () => clearTimeout(timeout); // Clear timeout on component unmount
  }, [currentTestimonialIndex]);

  const quoteBoxStyle = {
    maxWidth: { xs: '90%', sm: '80%', md: '640px' }, // example values
    margin: '0 auto', // Center the quote box
    textAlign: 'center', // Center-align the text
  };
  

  return (
    <section className="relative">
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -mb-32"
        aria-hidden="true"
      >
        <svg width="1760" height="518" viewBox="0 0 1760 518" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-02">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g transform="translate(0 -3)" fill="url(#illustration-02)" fillRule="evenodd">
            <circle cx="1630" cy="128" r="128" />
            <circle cx="178" cy="481" r="40" />
          </g>
        </svg>
      </div>

      <div id="testimonials" className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="tw-h2 h2 mb-4">{t('testimonials.sectionTitle')}</h2>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out">
            {t('testimonials.sectionSubtitle')}
            </p>
          </div>
          

          

          <div className="max-w-3xl mx-auto mt-20" data-aos="zoom-y-out">
          
            <div className="relative flex items-start border-2 border-gray-200 rounded bg-white">
            
              <div className="text-center px-12 py-8 pt-20 mx-4 md:mx-0">
              <Fade key={currentTestimonialIndex}>
              
                <div className="absolute top-0 -mt-8 left-1/2 transform -translate-x-1/2" style={{ left: '50%', transform: 'translateX(-50%)' }}>
                  <svg
                    className="absolute top-0 right-0 -mt-3 -mr-8 w-16 h-16 fill-current text-blue-500"
                    viewBox="0 0 64 64"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M37.89 58.338c-2.648-5.63-3.572-10.045-2.774-13.249.8-3.203 8.711-13.383 23.737-30.538l2.135.532c-6.552 10.033-10.532 17.87-11.939 23.515-.583 2.34.22 6.158 2.41 11.457l-13.57 8.283zm-26.963-6.56c-2.648-5.63-3.572-10.046-2.773-13.25.799-3.203 8.71-13.382 23.736-30.538l2.136.533c-6.552 10.032-10.532 17.87-11.94 23.515-.583 2.339.22 6.158 2.41 11.456l-13.57 8.283z" />
                  </svg>
                  <Image
                    className="relative rounded-full"
                    src={
                      //currentTestimonialIndex === 0
                        //? TestimonialImage
                        //: currentTestimonialIndex === 1
                        //? 
                        TestimonialImage2
                        //: TestimonialImage3 // Add this line
                    }
                    width={96}
                    height={96}
                    alt={`Testimonial ${currentTestimonialIndex + 1}`}
                  />
                </div>
                

             
                <blockquote className="text-xl font-medium mb-4" 
    style={{ 
      maxWidth: '90%', // Adjust this value to make the quote wider on mobile
      margin: '0 auto' // This centers the blockquote
    }}>
    {testimonials[currentTestimonialIndex].quote}
  </blockquote>
              
                
                <cite className="block font-bold text-lg not-italic mb-1">
                  {testimonials[currentTestimonialIndex].author}
                </cite>
                <div className="text-gray-600">
                  <span>{testimonials[currentTestimonialIndex].position}</span>{' '}
                  <a
                    className="tw-a text-blue-600 hover:underline"
                    href={testimonials[currentTestimonialIndex].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    
                  >
                    {testimonials[currentTestimonialIndex].company}
                  </a>
                  
                  
                </div>
                </Fade>
               
                
                <br></br>
                
                <div>
                
                <a
                  className="tw-a btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0"
                  onClick={showNextTestimonial}
                >
                  {t('testimonials.nextTestimonialButton')}
                </a>
              </div>
                
              </div>
              
            </div>
            
          </div>
          
        </div>
        
      </div>
      
    </section>
  );
}
