import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { InputType, Input, ReducerState } from 'types';
import DateInput from './DateInput';
import DropDown from './DropDown';
import RadioCollection from './RadioCollection';
import { StandardInput } from './StandardInput';
import { useSelector } from 'react-redux';
import CheckboxInput from './Checkbox';
import { useParams } from 'react-router-dom';

interface FormSelectorProps {
  input: Input;
  inputIndex: number;
  sectionIndex: number;
}

const FormSelector = ({ input, sectionIndex }: FormSelectorProps) => {
  const caseId = useSelector((state: ReducerState) => state.client.currentCase);
  const { dependentId = '', pageId = '' } = useParams();
  //TODO: grab and store dependent id
  const { inputType } = input;
  const [defaultValue, setDefaultValue] = useState<string | undefined>(undefined);
  const language = useSelector((state: ReducerState) => state.account.language);

  const getsessionStorageString = (ptvKey: any = '') => {
    let localId = pageId + '/' + caseId + '/return/' + dependentId; // TODO: follow by the depedent id
    if (ptvKey instanceof Array) {
      for (var wordIndex in ptvKey) {
        localId = localId + '/' + ptvKey[wordIndex];
      }
      return localId;
    } else {
      return '';
    }
  };

  const getTextFromValue = (value) => {
    if (input?.options?.length > 0) {
      for (var optionIndex in input.options) {
        if (input.options[optionIndex].value === value) {
          return input.options[optionIndex].label.en;
        }
      }
    } else {
      return value || '';
    }
  };

  const handleChange = (e: any) => {
    if (e.target.value.length > input?.validation?.charLength) {
      e.preventDefault();
      e.target.value = e.target.value.substring(0, input?.validation?.charLength);
    }
    for (var ptvKey in input.pathToValue) {
      sessionStorage.setItem(
        getsessionStorageString(input?.pathToValue?.[ptvKey]),
        JSON.stringify({
          value: e.target.value,
          prompt: input.prompt,
          text: getTextFromValue(e?.target?.value),
          isImmpactVal: true,
        })
      );
    }
    setDefaultValue(e.target.value);
  };

  const handleDateChange = (e: any) => {
    for (var ptvKey in input.pathToValue) {
      sessionStorage.setItem(
        getsessionStorageString(input?.pathToValue?.[ptvKey]),
        JSON.stringify({
          value: e?.$d || null,
          prompt: input?.prompt,
          text: getTextFromValue(e?.$d),
          isImmpactVal: true,
          format: input?.validation?.ymOnly ? 'YYYYMM' : 'YYYYMMDD',
        })
      );
    }
    setDefaultValue(e?.$d);
  };

  const handleDropdownChange = (val: any) => {
    for (var ptvKey in input.pathToValue) {
      sessionStorage.setItem(
        getsessionStorageString(input?.pathToValue?.[ptvKey]),
        JSON.stringify({ value: val, prompt: input.prompt, text: getTextFromValue(val), isImmpactVal: true })
      );
    }
    // setDefaultValue(e.$d);
  };

  const getDefaultValue = () => {
    const sessionStorageValue = JSON.parse(
      sessionStorage.getItem(getsessionStorageString(input?.pathToValue?.['0'])) || `{}`
    )?.value;
    if (sessionStorageValue) {
      return sessionStorageValue;
    } else {
      return input?.value?.value || '';
    }
  };

  // load in saved values in the local storage
  useEffect(() => {
    const sessionStorageValue = JSON.parse(
      sessionStorage.getItem(getsessionStorageString(input?.pathToValue?.['0'])) || `{}`
    )?.value;
    if (sessionStorageValue) {
      setDefaultValue(sessionStorageValue);
    } else {
      setDefaultValue(input?.value?.value || '');
    }
  }, []);

  return (
    <>
      <Typography
        sx={{
          marginLeft: '1.7rem',
          height: '3em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Stack direction={'row'}>
          {input?.prompt?.[language]}{' '}
          {input?.validation?.isRequired && <span style={{ color: 'red', marginLeft: '5px' }}>&#42;</span>}
        </Stack>
      </Typography>
      {inputType === InputType.INPUT_TEXT && input.validation?.numOnly ? (
        <StandardInput type="number" value={defaultValue} onChange={handleChange} 
        inputProps={{ maxLength: input?.validation?.charLength }}
        />
      ) : inputType === InputType.INPUT_TEXT ? (
        <StandardInput
          required
          value={defaultValue}
          onChange={handleChange}
          inputProps={{ maxLength: input?.validation?.charLength }}
        />
      ) : inputType === InputType.TEXT_BOX ? (
        <StandardInput
          value={defaultValue}
          multiline
          rows={4}
          maxRows={4}
          sx={{ height: '120px' }}
          defaultValue={input?.value?.value}
          onChange={handleChange}
        />
      ) : inputType === InputType.DROPDOWN ? (
        <DropDown
          input={input}
          sectionIndex={sectionIndex}
          onChange={handleDropdownChange}
          getDefaultValue={getDefaultValue}
        />
      ) : inputType === InputType.RADIO ? (
        <RadioCollection
          input={input}
          sectionIndex={sectionIndex}
          defaultValue={defaultValue}
          onChange={handleChange}
          getDefaultValue={getDefaultValue}
        />
      ) : inputType === InputType.DATE && input.validation?.ymOnly ? (
        <DateInput isMonthYear input={input} defaultValue={defaultValue} onChange={handleDateChange} />
      ) : inputType === InputType.DATE ? (
        <DateInput input={input} defaultValue={defaultValue} onChange={handleDateChange} />
      ) : inputType === InputType.CHECKBOX ? (
        <CheckboxInput defaultValue={defaultValue} onChange={handleChange} />
      ) : null}
    </>
  );
};

export default FormSelector;
