import { Helmet } from 'react-helmet-async';
import { Reducer, useEffect, useState } from 'react';
// mock
// import LIST from '../_mock/user';
import { useApiClient } from 'apiClient';
import { useSelector } from 'react-redux';
import { ReducerState } from 'types/types';
import SelectModal from 'components/action-modals/SelectModal';
import { ModalType } from 'types';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useIPR } from 'utils/hooks';
import ImmpactTable from 'landing/components/ui/table';
import { Button, Container, Stack, Typography } from '@mui/material';
import Iconify from 'components/iconify';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export default function DependentPage() {
  const [modalOpen, setModalOpen] = useState<ModalType | undefined>(undefined);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | undefined>(undefined);
  const { getCasePages, getDependents, removeDependent } = useApiClient();
  const navigate = useNavigate();

  const caseId = useSelector((state: ReducerState) => state?.client?.currentUserId);
  // const data = useSelector((state: ReducerState) => state.client.value);
  const dependants = useSelector((state: ReducerState) => state.form.dependants);
  const processedData: any[][] = [];
  dependants?.forEach?.((row, index) => {
    const DOBString = `${new Date(row?.dateOfBirth?.value).getFullYear() || ''}-${
      new Date(row?.dateOfBirth?.value).getMonth() + 1 || ''
    }-${new Date(row?.dateOfBirth?.value).getDate() || ''}`;
    processedData[index] = [];
    processedData[index][0] = { label: row?.givenName?.value || '-', align: 'left' };
    processedData[index][1] = { label: row?.lastName?.value || '-', align: 'left' };
    processedData[index][2] = { label: row?.accompanying?.value || '-', align: 'left' };
    processedData[index][3] = { label: DOBString || '-', align: 'left' };
  });
  const mainApplicantData = [
    [
      { label: dependants?.[0]?.givenName?.value || '-', align: 'left' },
      { label: dependants?.[0]?.lastName?.value || '-', align: 'left' },
      {
        label:
          `${new Date(dependants?.[0]?.dateOfBirth?.value).getFullYear() || ''}-${
            new Date(dependants?.[0]?.dateOfBirth?.value).getMonth() + 1 || ''
          }-${new Date(dependants?.[0]?.dateOfBirth?.value).getDate() || ''}` || '-',
        align: 'left',
      },
    ],
  ];
  const dependantsData = processedData.slice(1);
  const handleCloseModal = () => {
    setModalOpen(undefined);
  };
  const handleNew = async () => {
    setModalOpen(ModalType.ADD_DEPENDENT);
  };

  useEffect(() => {
    getDependents();
  }, []);

  const { t } = useTranslation();

  const handleReturn = () => {
    navigate(`../cases/${caseId}`);
  };

  return (
    <>
      <SelectModal open={modalOpen} handleClose={handleCloseModal} args={{isEdit, editId}}/>
      <Helmet>
        <title> Applicant | Immpact </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('dashboard.applicantAndDependant')}
          </Typography>
          <div>
            <Button
              variant="contained"
              style={{ marginRight: '10px' }}
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleNew}
            >
              {t('dashboard.addDependant')}
            </Button>
            <Button variant="contained" endIcon={<Iconify icon="eva:arrow-forward-outline" />} onClick={handleReturn}>
              Return
            </Button>
          </div>
        </Stack>
        <Typography variant="h6" sx={{ marginBottom: '5px' }}>
          Main Applicant</Typography>
        <ImmpactTable
          columnHeader={[
            { id: 'depFirstName', label: t('dashboard.firstName'), alignRight: false },
            { id: 'depLastName', label: t('dashboard.familyName'), alignRight: false },
            { id: 'depDOB', label: t('dashboard.dob'), alignRight: false },
            { id: '', label: '', alignRight: false },
          ]}
          tableValues={mainApplicantData}
          tableFunctions={[
            {
              label: t('dashboard.editApplication'),
              logo: 'eva:edit-outline',
              function: (index) => {
                // GET CASE PAGES
                getCasePages(dependants?.[index]?.id);
              },
            },
            
          ]}
          searchPlaceHolder={''}
          skinny
        />
        <div style={{ height: '20px' }}></div>
        <Typography variant="h6" sx={{ marginBottom: '5px' }}>
          Dependants
        </Typography>
        <ImmpactTable
          columnHeader={[
            { id: 'depFirstName', label: t('dashboard.firstName'), alignRight: false },
            { id: 'depLastName', label: t('dashboard.familyName'), alignRight: false },
            { id: 'depAccompanying', label: t('dashboard.accompanying'), alignRight: false },
            { id: 'depDOB', label: t('dashboard.dob'), alignRight: false },
            { id: '', label: '', alignRight: false },
          ]}
          tableValues={dependantsData}
          tableFunctions={[
            {
              label: t('dashboard.editApplication'),
              logo: 'eva:edit-outline',
              function: (index) => {
                // GET CASE PAGES
                getCasePages(dependants?.[index+1]?.id);
              },
            },
            {
              label: t('dashboard.editDependant'),
              logo: 'mage:exchange-a',
              function: (index) => {
                // GET CASE PAGES
                setIsEdit(true);
                setEditId(dependants?.[index+1]?.id);
                handleNew()
                // getCasePages(dependants?.[index+1]?.id);
              },
            },
            {
              label: t('dashboard.deleteDependant'),
              logo: 'eva:trash-2-outline',
              function: (index) => {
                // DELETE DEPENDENT
                removeDependent(dependants?.[index+1]?.id);
                getDependents();
              },
            },
          ]}
          searchPlaceHolder={''}
        />
      </Container>
    </>
  );
}
