import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

// @mui
import { Stack, Button, Container, Typography, Grid } from '@mui/material';
import { useApiClient } from 'apiClient';
import Iconify from 'components/iconify';
import SelectModal from 'components/action-modals/SelectModal';
import { ModalType } from 'types';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export default function DocScan() {
  const [open, setOpen] = useState<ModalType | undefined>(undefined);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 300,
    maxHeight: '90%',
    maxWidth: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #3b82f6', // Updated the color to blue
    boxShadow: 24,
    p: 4,
    borderRadius: '10px', // Added this to round the corners of the modal
    overflow: 'auto',
  };

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Helmet>
        <title> Users | Immpact </title>
      </Helmet>

      <SelectModal open={open} handleClose={handleClose} />
      <Container>
        <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom sx={{ marginBottom: '5%' }}>
            {t('dashboard.documentScan')}
          </Typography>
          <Typography variant="body1">{t('dashboard.documentScanDescription')}</Typography>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '5%',
          }}
        >
          <Button onClick={()=>setOpen(ModalType.SELECT_DOC_SCANNER)} sx={{ width: '200px' }} startIcon={<Iconify icon="eva:plus-fill" />} variant="contained">
            Add Document
          </Button>
        </Box>
      </Container>
    </>
  );
}
