import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {

    const { t } = useTranslation();

    return (
        <div>
    <p>
        {t('policy.welcome')}
    </p>
    <h3>
        {t('policy.termsTitle')}
    </h3>
    <p>
        {t('policy.termsDesc')}
    </p>
    <h3>
        {t('policy.registrationTitle')}
    </h3>
    <p>
        <strong>{t('policy.registrationDesc1')}</strong>
    </p>
    <ul>
        <li>{t('policy.registrationDesc2')}</li>
        <li>{t('policy.registrationDesc3')}</li>
        <li>{t('policy.registrationDesc4')}</li>
        <li>{t('policy.registrationDesc5')}</li>
    </ul>

    <h3>{t('policy.conductTitle')}</h3>
    <p>
        <strong>{t('policy.conductDesc1')}</strong>
    </p>
    <ul>
        <li>{t('policy.conductDesc2')}</li>
        <li>{t('policy.conductDesc3')}</li>
        <li>{t('policy.conductDesc4')}</li>
        <li>{t('policy.conductDesc5')}</li>
        <li>{t('policy.conductDesc6')}</li>
        <li>{t('policy.conductDesc7')}</li>
    </ul>

    <h3>{t('policy.contentTitle')}</h3>
    <p>
        {t('policy.contentDesc')}
    </p>
    <h3>
        {t('policy.rightsTitle')}
    </h3>
    <p>
        {t('policy.rightsDesc')}
    </p>

    <h3>
        {t('policy.securityTitle')}
    </h3>
    <p>
        {t('policy.securityDesc')}
    </p>
    <h3>
        {t('policy.privacyTitle')}
    </h3>
    <p>
        {t('policy.privacyDesc')}
    </p>

    <h3>
        {t('policy.liabilityTitle')}
    </h3>
    <p>
        {t('policy.libilityDesc')}
    </p>
    <h3>
        {t('policy.IndemnificationTitle')}
    </h3>
    <p>
        {t('policy.IndemnificationDesc')}
    </p>
    <h3>
        {t('policy.TerminationTitle')}
    </h3>
    <p>
        {t('policy.TerminationDesc')}
    </p>

    <h3>
        {t('policy.PaymentTitle')}
    </h3>
    <p>
    {t('policy.PaymentDesc')}
    </p>
    <h3>
        {t('policy.ChangesTitle')}
    </h3>
    <p>
        {t('policy.changeDesc')}
    </p>
    <h3>
        {t('policy.lawTitle')}
    </h3>
    <p>
        {t('policy.lawDesc')}
    </p>
    <h3>
        {t('policy.contactTitle')}
    </h3>
    <p>
        {t('policy.contactDesc1')}
    </p>
    <p>
        {t('policy.contactDesc2')}
    </p>
</div>


    )
} 