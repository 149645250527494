import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// mock
// import LIST from '../_mock/user';
import { useApiClient } from 'apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { populateCurrentCase } from 'redux/clientSlice';
import { ReducerState } from 'types/types';
import SelectModal from 'components/action-modals/SelectModal';
import { ModalType } from 'types';
import { useNavigate } from 'react-router-dom';
import { useIPR } from 'utils/hooks';
import ImmpactTable from 'landing/components/ui/table';
import { Button, Container, Stack, Typography, colors } from '@mui/material';
import Iconify from 'components/iconify';
import { useTranslation } from 'react-i18next';
import { Box, Modal } from '@mui/material';
import AutomationModal from 'landing/components/automationModal';
import { es } from 'date-fns/locale';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export default function TablePage() {
  const [modalOpen, setModalOpen] = useState<ModalType | undefined>(undefined);
  const [args, setArgs] = useState<any>(null);
  const dispatch = useDispatch();
  const { getCasePages, getallCases } = useApiClient();
  const { generateIPR } = useIPR();
  const navigate = useNavigate();

  const data = useSelector((state: ReducerState) => state?.client?.currentUser);
  const accountType = useSelector((state: ReducerState) => state?.account?.accountType);
  const userId = useSelector((state: ReducerState) => state?.account?.uid);
  const clientId = useSelector((state: ReducerState) => state?.client?.currentUserId);
  const processedData: any[][] = [];
  data.forEach((row, index) => {
    processedData[index] = [];
    processedData[index][0] = { label: row.name, align: 'left' };
    processedData[index][1] = { label: row.type, align: 'left' };
  });
  const handleCloseModal = () => {
    setModalOpen(undefined);
  };

  const handleNew = async () => {
    setModalOpen(ModalType.ADD_CASE);
  };

  const { t } = useTranslation();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 500,
    maxHeight: '90%',
    maxWidth: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #3b82f6',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    overflow: 'auto',
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleReturn = () => {
    navigate(`/dashaboard/user`);
  };

  useEffect(() => {
    if (accountType === 'User') {
      getallCases(userId);
    }
  },[])
  return (
    <>
      <SelectModal open={modalOpen} handleClose={handleCloseModal} args={args} />
      <Helmet>
        <title> Cases | Immpact </title>
      </Helmet>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 id="modal-modal-title">{t('Before automating your case, please note the following:')}</h2>
          {/* <AutomationModal handleClose={closeModal} /> */}
        </Box>
      </Modal>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('dashboard.case')}
          </Typography>
          {accountType === 'Rep' && (
            <div>
              <Button
                variant="contained"
                style={{ marginRight: '10px' }}
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={handleNew}
              >
                {t('dashboard.newCase')}
              </Button>
              <Button variant="contained" endIcon={<Iconify icon="eva:arrow-forward-outline" />} onClick={handleReturn}>
                Return
              </Button>
            </div>
          )}
        </Stack>

        <ImmpactTable
          columnHeader={[
            { id: 'caseName', label: t('dashboard.caseName'), alignRight: false },
            { id: 'caseType', label: t('dashboard.caseType'), alignRight: false },
            { id: '', label: '', alignRight: false },
          ]}
          tableValues={processedData}
          tableFunctions={(() => {
            const table = [
              {
                label: t('dashboard.edit'),
                logo: 'eva:edit-fill',
                function: (currentSelection: string) => {
                  dispatch(populateCurrentCase(data[parseInt(currentSelection)].id));
                  navigate(`../case/dependents`);
                },
              },
              {
                label: t('dashboard.IPR'),
                logo: 'carbon:report',
                function: (currentSelection: any) => {
                  generateIPR(data[parseInt(currentSelection)].id);
                },
              },
            ];
            if (accountType === 'Rep') {
              table.unshift({
                label: t('dashboard.automate'),
                color: colors.green[500],
                logo: 'mdi:clock-fast',

                function: (currentSelection: string) => {
                  dispatch(populateCurrentCase(data[parseInt(currentSelection)].id));
                  setModalOpen(ModalType.AUTOMATE);
                  setArgs({
                    caseId: data[parseInt(currentSelection)].id,
                  })
                  // openModal();
                },
              });

              table.push({
                color: colors.red[500],
                label: t('dashboard.delete'),
                logo: 'eva:trash-2-outline',
                function: (currentSelection: string) => {
                  setModalOpen(ModalType.DELETE_CASE);
                  setArgs({
                    caseId: data[parseInt(currentSelection)].id,
                    clientId,
                  });
                },
              });
              return table;
            } else {
              return table;
            }
          })()}
          searchPlaceHolder={''}
        />
      </Container>
    </>
  );
}
