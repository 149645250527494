import { alpha, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, Modal, OutlinedInput, styled, TextField, Typography } from '@mui/material'
import { useApiClient } from 'apiClient';
import { StandardInput } from 'components/form-input';
import Iconify from 'components/iconify';
import { AstPath } from 'prettier';
import React, { useState } from 'react'

interface UpdateUserModal {
  open: boolean;
  handleClose: () => void;
}

const UpdateUserModal = ({ open, handleClose }: UpdateUserModal) => {
  const [givenName, setGivenName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const handleNewClient = async () => {
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: '5%', marginLeft: '2%' }}>Update Client</DialogTitle>
      <DialogContent sx={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <DialogContentText sx={{ marginBottom: '5%', marginLeft: '2%' }}>
          Update the user's profile information
        </DialogContentText>
        <StandardInput
          placeholder="Given Name..."
          onChange={(e) => {
            setGivenName(e?.target?.value);
          }}
        />
        <StandardInput
          placeholder="Last Name..."
          onChange={(e) => {
            setLastName(e?.target?.value);
          }}
        />
        <StandardInput
          placeholder="Email..."
          type="email"
          onChange={(e) => {
            setEmail(e?.target?.value);
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleNewClient}>Update</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUserModal;