import { createSlice } from "@reduxjs/toolkit";
import { LoginStatus } from "types";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    status: LoginStatus.LOGGED_OUT, 
    entryChecked: false, 

  }, 
  reducers: {
    logIn: (state) => {
      state.status = LoginStatus.FULLY_SETUP;
    },
    logOut: (state) => {
      state.status = LoginStatus.LOGGED_OUT;
      state.entryChecked = false;
    },
    toSetup: (state) => {
      state.status = LoginStatus.NOT_SETUP;
    },
    checkEntry: (state)=>{
      state.entryChecked = true;
    }

  },
});

export const { logIn, logOut, toSetup, checkEntry} = loginSlice.actions;
export default loginSlice;