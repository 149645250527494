import { createSlice } from "@reduxjs/toolkit";

const returnSlice = createSlice({
    name: "return",
    initialState: {
        returnObject: {}
    },
    reducers: {
        setReturnObject: (state, action) => {
            state.returnObject = action.payload
        },
        addToReturn: (state, action) => {
            let currentLevel: Record<string, any> = state.returnObject;
            for (const element of action.payload.array) {
                if (action?.payload?.array?.indexOf?.(element) === action?.payload?.array?.length - 1) {
                    currentLevel[element] = action.payload.value
                }
                if (!currentLevel[element]) {

                    currentLevel[element] = {};
                }
                currentLevel = currentLevel[element];
            }
        }
    }   
})

export const { setReturnObject, addToReturn } = returnSlice.actions;
export default returnSlice;