import { alpha, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, Modal, OutlinedInput, styled, TextField, Typography } from '@mui/material'
import { useApiClient } from 'apiClient';
import { StandardInput } from 'components/form-input';
import Iconify from 'components/iconify';
import { AstPath } from 'prettier';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { ReducerState } from 'types';

interface DeleteUserModalProps {
    open: boolean;
    handleClose: () => void;
    selectedClient?: any;
}



const DeleteUserModal = ({open , handleClose, selectedClient = ""}: DeleteUserModalProps) => {
  const repId = useSelector((state: ReducerState) => state.account.uid);
  const { deleteClient } = useApiClient();
    const handleDeleteUser = async () => {
      deleteClient(repId, selectedClient);
      handleClose();
    }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: '5%', marginLeft: '2%' }}>All data will be lost</DialogTitle>
      <DialogContent sx={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <DialogContentText sx={{ marginLeft: '2%', marginBottom: '2%' }}>
          Deleting the user will permanently delete all their account information as well as any forms that may have
          been completed by them or on their behalf.
        </DialogContentText>
        <DialogContentText sx={{ marginBottom: '5%', marginLeft: '2%' }}>
          Are you sure you would like to delete this user?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleDeleteUser} variant="contained" sx={{ backgroundColor: 'red' }}>
          Delete Client
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteUserModal;