import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { DropDownOption, Input, ReducerState } from 'types';
import { useDependentInputs } from 'utils/hooks';

interface DropDownProps {
  input: Input;
  sectionIndex: number;
  getDefaultValue?: () => string;
  onChange?: (e: any) => void;
}

const getValueOption = (opts: [], value: string) => {
  if (!(value == null) && !(value == '')) {
    const option = opts.filter((option) => option?.value?.toString() === value);
    return option[0];
  }
};

const filterOptions = (options: DropDownOption[], language: string) => {
  let filteredOptions = [];

  for (var optionIndex in options) {
    if (options[optionIndex].value == "0: null") {
      continue;
    }
    filteredOptions.push({ label: options[optionIndex].label[language], value: options[optionIndex]?.value });
  }

  filteredOptions.sort(function (a, b) {
    var keyA = a.label,
      keyB = b.label;
    // Compare the 2 values
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  return filteredOptions;
};
export const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: '57px',
    height: '57px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: '30px',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state: any) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '30px',
  }),
  container: (provided: any) => ({
    ...provided,
    width: '90%',
    marginLeft: '1.7rem',
    fontFamily: 'Arial',
  }),
  option: (provided: any) => ({
    ...provided,
    zIndex: 10000,
  }),
  menu: (provided: any) => ({
    ...provided,
  }),
  menuList: (provided: any) => ({
    ...provided,
  }),
  singleValue: (provided: any) => ({
    ...provided,
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
};
const DropDown = ({ input, sectionIndex, onChange, getDefaultValue = ()=> ""}: DropDownProps) => {
  const { triggerSubsection } = useDependentInputs(input, sectionIndex);
  const { dependentId = '', pageId = '' } = useParams();
  const language = useSelector((state: ReducerState) => state.account.language);
  const caseId = useSelector((state: ReducerState) => state.client.currentCase);
  const [defaultValue, setDefaultValue] = useState<string | undefined>(undefined);
  const getsessionStorageString = (ptvKey: any = '') => {
    let localId = pageId + '/' + caseId + '/return/' + dependentId; // TODO: follow by the depedent id
    if (ptvKey instanceof Array) {
      for (var wordIndex in ptvKey) {
        localId = localId + '/' + ptvKey[wordIndex];
      }
      return localId;
    } else {
      return '';
    }
  };
  // useEffect(() => {
  //   const sessionStorageValue = sessionStorage.getItem(getsessionStorageString(input?.pathToValue?.['0'])) || `{}`;
  //   if (sessionStorageValue) {
  //     setDefaultValue(sessionStorageValue);
  //   } else {
  //     setDefaultValue(input?.value?.value || '');
  //   }
  // }, []);

  return (
    <>
      <ReactSelect
        menuPortalTarget={document.body}
        styles={customStyles}
        options={filterOptions(input?.options, language)}
        defaultValue={getValueOption(filterOptions(input?.options, language), getDefaultValue()||"")}
        value={getValueOption(filterOptions(input?.options, language), getDefaultValue()||"")}
        onChange={(selection) => {
          onChange(selection?.value);
          setDefaultValue(selection?.value);
          triggerSubsection(selection?.value);
        }}
        minMenuHeight={200}
        maxMenuHeight={200}
      />
    </>
  );
};

export default DropDown;
