import React from 'react';
import DM from "../images/danielMakhlin.jpeg";
import EK from "../images/edenKozlov.jpeg";
import SG from "../images/shadyGuindi.jpeg";
import RS from "../images/ronShmoilov.jpeg";
import { useTranslation } from 'react-i18next';

export default function Partners() {

    const { t } = useTranslation();

    return (
        <div className="flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <div className="flex flex-col items-center mb-4">
                <img src={EK} alt="Founder 1" className="mb-2 rounded-full w-24 h-24" />
                <h3 className="text-lg font-semibold">Eden Kozlov</h3>
                <p className="text-center">{t('partners.edenDesc')}</p>
            </div>
            <div className="flex flex-col items-center mb-4">
                <img src={RS} alt="Founder 2" className="mb-2 rounded-full w-24 h-24" />
                <h3 className="text-lg font-semibold">Ron Shmoilov</h3>
                <p className="text-center">{t('partners.ronDesc')}</p>
            </div>
            <div className="flex flex-col items-center mb-4">
                <img src={DM} alt="Founder 3" className="mb-2 rounded-full w-24 h-24" />
                <h3 className="text-lg font-semibold">Daniel Makhlin</h3>
                <p className="text-center">{t('partners.danielDesc')}</p>
            </div>
            <div className="flex flex-col items-center">
                <img src={SG} alt="Founder 4" className="mb-2 rounded-full w-24 h-24" />
                <h3 className="text-lg font-semibold">Shady Guindi</h3>
                <p className="text-center">{t('partners.shadyDesc')}</p>
            </div>
        </div>
    );
}
