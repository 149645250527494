import { icon } from 'components/iconify';
import { useTranslation } from 'react-i18next';

export const useImmpactRoutes = () => {
  const { t } = useTranslation();
  const initialDashboard = [
    {
      name: t('dashboard.users'),
      id: '/dashboard/user',
      icon: icon('ic_user'),
    },
    {
      name: 'User Guide',
      id: `../dashboard/user-guide`,
      icon: icon('ic_guide'),
    },
    {
      name: 'News',
      id: `../dashboard/news`,
      icon: icon('ic_news'),
    },
    {
      name: t('dashboard.settings'),
      id: '/dashboard/settings',
      icon: icon('ic_settings'),
    },
  ];

  const dependantsDashboard = [
    {
      name: 'Application',
      id: `../${location?.pathname}`,
      icon: icon('ic_user'),
    },
    {
      name: 'Document Scan',
      id: `../dashboard/case/doc-scan`,
      icon: icon('ic_document'),
    },
  ];

  const getInitialDashbaordSidbar = () => initialDashboard;
  const getDependantsDashbaordSidbar = () => dependantsDashboard;
  const getCasesDashbaordSidbar = (uid: any) => {
    return [
      {
        name: 'Cases',
        id: `../${location?.pathname}`,
        icon: icon('ic_case'),
      },
      {
        name: 'Chat With Client',
        id: `../dashboard/chat/${uid}`,
        icon: icon('ic_chat'),
      },
    //   {
    //     name: 'User Info',
    //     id: `../dashboard/userInfo/${uid}`,
    //     icon: icon('ic_user'),
    //   },
      {
        name: 'Billing',
        id: `../dashboard/billing/${uid}`,
        icon: icon('ic_billing'),
      },
    ];
  };
  return {
    getInitialDashbaordSidbar,
    getDependantsDashbaordSidbar,
    getCasesDashbaordSidbar,
  };
};
