import { Navigate, RouteObject, useLocation, useNavigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
//
import TablePage from '../pages/TablePage';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import FormPage from 'pages/FormPage';
import { AccountType, LoginStatus, ReducerState, TableType } from 'types';
import AccountSetup from 'pages/AccountSetup';
import { useApiClient } from 'apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { Reducer, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { setAccessToken, setEmail, setUid } from 'redux/accountSlice';
import { app } from 'utils/firebase';
import { populatePages } from 'redux/formSlice';
import { icon } from 'components/iconify';
import LandingPage from 'landing/LandingPage';
import UsersPage from 'pages/UsersPage';
import CasesPage from 'pages/CasesPage';
import DependentPage from 'pages/DependentsPage';
import ComingSoon from 'pages/ComingSoon';
import { useTranslation } from 'react-i18next';
import SettingsPage from 'pages/SettingsPage';
import ForgotPassword from 'pages/ForgotPassword';
import DocScan from 'pages/DocScan';
import { useImmpactRoutes } from 'utils/hooks/useImmpactRoutes';
import Guide from 'landing/components/productGuide';
import News from 'pages/News';

// ----------------------------------------------------------------------

export default function Router() {
  // persistent login
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { atEntry } = useApiClient();
  const entryChecked = useSelector((state: ReducerState) => state.login.entryChecked);
  const accountType = useSelector((state: ReducerState) => state.account.accountType);
  const uid = useSelector((state: ReducerState) => state.account.uid);
  const { getInitialDashbaordSidbar, getDependantsDashbaordSidbar } = useImmpactRoutes();

  const location = useLocation();
  //persist login
  useEffect(() => {
    sessionStorage.setItem('lastPath', location.pathname);
    const auth = getAuth(app);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (!entryChecked && user.metadata.creationTime !== user.metadata.lastSignInTime) {
          const accessToken = await user.getIdToken();
          dispatch(setAccessToken(accessToken));
          await atEntry(accessToken);
          dispatch(setUid(user.uid));
          dispatch(setEmail(user.email));
        }
      }
    });

    if (accountType === AccountType.REP) {
      dispatch(populatePages(getInitialDashbaordSidbar()));
    } else {
      populatePages(getDependantsDashbaordSidbar());
    }
    const lastNavigated = sessionStorage.getItem('lastPath');
    navigate(lastNavigated !== '/' ? lastNavigated || 'landing' : 'landing');
  }, []);

  const getDashboardPagesByAccountType = () => {
    if (accountType === AccountType.REP) {
      return [
        { element: <Navigate to="/dashboard/user" />, index: true },
        { path: 'user', element: <UsersPage /> },
        { path: 'settings', element: <SettingsPage /> },
        { path: 'subscriptions', element: <ComingSoon /> },
        { path: 'billing/:userId', element: <ComingSoon /> },
        // { path: 'userInfo/:userId', element: <ComingSoon /> },
        { path: 'chat/:userId', element: <ComingSoon /> },
        { path: 'news', element: <News /> },
        { path: 'user-guide', element: <Guide /> },
        { path: 'cases/:userId', element: <CasesPage /> },
        { path: 'case/dependents', element: <DependentPage /> },
        { path: 'case/doc-scan', element: <DocScan /> },
        { path: 'forms/:pageId/:dependentId', element: <FormPage /> },
      ];
    } else {
      return [
        { element: <Navigate to={`dashboard/cases/${uid}`} />, index: true },
        { path: 'cases/:userId', element: <CasesPage /> },
        { path: 'forms/:pageId/:dependentId', element: <FormPage /> },
        { path: 'case/dependents', element: <DependentPage /> },
      ];
    }
  };

  const loginStatus = useSelector((state: ReducerState) => state.login.status);
  const getRoutesFromLoginStatus = () => {
    if (loginStatus === LoginStatus.FULLY_SETUP) {
      return {
        path: '/dashboard',
        element: <DashboardLayout />,
        children: getDashboardPagesByAccountType(),
      };
    } else if (loginStatus === LoginStatus.LOGGED_OUT) {
      return {
        elemnt: <SimpleLayout />,
        children: [
          { path: 'thank-you', element: <ComingSoon /> },
          {
            path: 'landing',
            element: <LandingPage />,
          },
          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />,
          },
        ],
      };
    } else if (loginStatus === LoginStatus.NOT_SETUP) {
      return {
        elemnt: <SimpleLayout />,
        children: [
          {
            path: '/setup',
            element: <AccountSetup />,
          },
        ],
      };
    } else {
      return {} as RouteObject;
    }
  };
  const routes = useRoutes([
    getRoutesFromLoginStatus(),

    {
      path: '*',
      element:
        loginStatus === LoginStatus.LOGGED_OUT ? (
          <Navigate to="landing" replace />
        ) : loginStatus === LoginStatus.FULLY_SETUP && accountType === AccountType.REP ? (
          <Navigate to="/dashboard/user" />
        ) : loginStatus === LoginStatus.FULLY_SETUP && accountType === AccountType.USER ? (
          <Navigate to={`dashboard/cases/${uid}`} />
        ) : loginStatus === LoginStatus.NOT_SETUP ? (
          <Navigate to="/setup" />
        ) : (
          <Navigate to="landing" replace />
        ),
    },
  ]);

  return routes;
}
