import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Modal,
  OutlinedInput,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useApiClient } from 'apiClient';
import { StandardInput } from 'components/form-input';
import Iconify from 'components/iconify';
import { AstPath } from 'prettier';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

interface AddUserModalProps {
  open: boolean;
  handleClose: () => void;
}

const AddUserModal = ({ open, handleClose }: AddUserModalProps) => {
  const uid = useSelector((state) => state?.account?.uid)
  const { associateUser } = useApiClient();
  const [repId, setRepId] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const handleNewClient = async () => {
    await associateUser(repId, uid);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: '5%', marginLeft: '4%' }}>Add your Rep code</DialogTitle>
      <DialogContent sx={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <DialogContentText sx={{ marginBottom: '5%', marginLeft: '4%' }}>
          Your rep should provide you a code to sign up with
        </DialogContentText>
        <StandardInput
          placeholder="Rep Code..."
          onChange={(e) => {
            setRepId(e?.target?.value);
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleNewClient}>Sign up</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
