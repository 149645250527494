import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToReturn } from 'redux/returnSlice';
import { DropDownOption, Input, ReducerState } from 'types';
import { useDependentInputs, useDependentSubsections } from 'utils/hooks';

interface RadioCollectionProps {
  input: Input;
  sectionIndex: number;
  defaultValue: string | undefined;
  onChange: (e: any) => void;
  getDefaultValue: () => string | undefined;
}

export const RadioCollection = ({
  input,
  sectionIndex,
  defaultValue,
  onChange,
  getDefaultValue,
}: RadioCollectionProps) => {
  const { triggerSubsection } = useDependentInputs(input, sectionIndex);
  const language = useSelector((state: ReducerState) => state.account.language);
  return (
    <>
      <RadioGroup
        sx={{ marginLeft: '1.7rem' }}
        value={getDefaultValue()}
        onChange={(e) => {
          onChange(e);
          triggerSubsection(e.target.value);
        }}
      >
        {(() => {
          let radios = [];
          // iterate over object
          for (var optionIndex in input?.options) {
            // print key and value on console
            radios.push(
              <FormControlLabel
                value={input?.options?.[optionIndex]?.value}
                control={<Radio />}
                label={input?.options?.[optionIndex]?.label[language]}
              />
            );
          }
          return radios;
        })()}
      </RadioGroup>
    </>
  );
};

export default RadioCollection;
