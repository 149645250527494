import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@mui/material';
import Iconify from 'components/iconify';
import { useApiClient } from 'apiClient';
import { useEffect, useState } from 'react';
import DropDown from 'components/form-input/DropDown';
import ReactSelect from 'react-select';
import { customStyles } from 'components/form-input/DropDown';
import { AxiosResponse } from 'axios';

interface AutomationModalProps {
  handleClose: () => void;
  open: boolean;
  caseId: string;
}

export default function AutomationModal({ handleClose, open, caseId }: AutomationModalProps) {
  const { getCase } = useApiClient();
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [selectedPortal, setSelectedPortal] = useState(null);
  const [caseData, setCaseData] = useState<void | AxiosResponse<any, any>>(undefined);
  const { t } = useTranslation();
  const handleAutomation = async () => {
    await getCase(caseId).then((res) => {
      setDataLoaded(true);
      setCaseData(res);
    });
  };

  useEffect(() => {
    open && handleAutomation();
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} sx={{ padding: '2%' }}>
      <div style={{ padding: '4%' }}>
        <ul>
          <li>We do NOT have access nor do we save your username or password on any portal.</li>
          <li>We do NOT have access to your clients’ information or any data beyond this point.</li>
          <li>We do NOT submit your application nor do we upload files.</li>
          <li>The software does not have the ability to delete cases, delete inputs, etc.</li>
          <li>Immpact understands the importance and the sensitivity of your data and privacy.</li>
        </ul>
        <h3>Instructions:</h3>
        <p>
          1. When clicking “Continue” below, you will be prompted to your respective portal’s sign in page. Proceed by
          signing in as you normally would <strong>(Do not yet click “Start Automation”)</strong>.
        </p>
        <p>
          2. Once signed in, you may create a new case by selecting one of your choice and naming it, again as you
          normally would <strong>(Do not yet click “Start Automation”)</strong>.
        </p>
        <p>
          3. When you find yourself on the empty profile page, you are free to click <strong>“Start Automation”</strong>{' '}
          and watch as your case completes itself.
        </p>
        <p>
          4. You can <strong>Stop Automation</strong> anytime you like if you need to.
        </p>
        <p>
          5. Finally, if there are any complications or questions. Please contact us at{' '}
          <strong>info@immpact-as.ca</strong> or use the <strong>“Report a Problem”</strong> feature in your dashboard
          under your profile.
        </p>
        <p>
          By clicking Automate you agree to Immpact's <u>terms of service</u> and <u>privacy policy</u>.
        </p>
        <div style={{ marginBottom: '5%', marginTop: '5%' }}>
          <ReactSelect
            styles={{ ...customStyles, container: (provided) => ({ ...provided, width: '100%' }) }}
            options={[
              {
                label: 'Licensed respresentative portal',
                value: 'LRP',
              },
              {
                label: 'Applicant portal',
                value: 'AP',
              },
            ]}
            onChange={(selection) => {
              setSelectedPortal(selection?.value);
              if (window.electron) {
                window.electron.sendMessageToMain(JSON.stringify({...caseData, portal: selection?.value}));
              }
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <Button variant="contained" startIcon={<Iconify icon="eva:close-outline" />} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            endIcon={<Iconify icon="eva:arrow-forward-outline" />}
            id="start-automation-fe"
            onClick={handleClose}
            disabled={!isDataLoaded || !selectedPortal}
          >
            Continue
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
