import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  getAdditionalUserInfo,
  updateProfile,
} from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { logIn, logOut, toSetup } from 'redux/loginSlice';
import { app } from 'utils/firebase';
import { GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { setAccessToken, setAccount, setEmail, setUid } from 'redux/accountSlice';
import { async } from '@firebase/util';
import { sendMessage } from 'redux/alertSlice';
import { useApiClient } from './useApiClient';
import { useEffect } from 'react';
import { AlertSeverity, ReducerState } from 'types';

export const useLogin = () => {
  const auth = getAuth(app);
  const dispatch = useDispatch();
  const { atEntry } = useApiClient();


  const googleLogin = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider)
      .then(async (userCredential) => {
        const user = userCredential.user;
        provider.getCustomParameters();
        const accessToken = await user.getIdToken();
        // set the access token to use in subsequent requests
        dispatch(setAccessToken(accessToken));
        dispatch(setUid(user.uid))
        // check the account status and redirect to appropriate page
        const profile = getAdditionalUserInfo?.(userCredential)?.profile;
        await atEntry(accessToken, {
          givenName: profile?.given_name,
          lastName: profile?.family_name,
        });
      })
      .catch((error) => {
        dispatch(
          sendMessage({
            open: true,
            message: error.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const register = async (firstName: string, lastName: string, email: string, password: string) => {
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        // set the access token to use in subsequent requests
        const accessToken = await user.getIdToken(true);
        dispatch(setAccessToken(accessToken));
        dispatch(setUid(user.uid)); 
        dispatch(setEmail(email));
        // check the account status and redirect to appropriate page
        await atEntry(accessToken, {
          givenName: firstName,
          lastName,
        });
      })
      .catch((error) => {
        dispatch(
          sendMessage({
            open: true,
            message: error.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };

  const login = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const accessToken = await user.getIdToken();
        // set the access token to use in subsequent requests
        dispatch(setAccessToken(accessToken));
        dispatch(setUid(user.uid))
        // check the account status and redirect to appropriate page
        await atEntry(accessToken);
      })
      .catch((error) => {
        dispatch(
          sendMessage({
            open: true,
            message: error.message,
            severity: AlertSeverity.ERROR,
          })
        );
      });
  };
  const logout = () => {
    signOut(auth)
      .then(() => {
        dispatch(logOut());
      })
      .catch((error) => {
        dispatch(sendMessage(error.message));
      });
  };
  return {
    googleLogin,
    register,
    login,
    logout,
  };
};
