import React, { useState } from 'react';
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Modal,
  OutlinedInput,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useApiClient } from 'apiClient';

interface AddUserModalProps {
  clientId: string;
  caseId: string;
  open: boolean;
  handleClose: () => void;
}

const DeleteCaseModal = ({ open, handleClose, clientId, caseId }: AddUserModalProps) => {
  const { deleteCase } = useApiClient();
  const handleDeleteCase = async () => {
    deleteCase(clientId, caseId);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: '5%', marginLeft: '2%' }}>All data will be lost</DialogTitle>
      <DialogContent sx={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <DialogContentText sx={{ marginLeft: '2%', marginBottom: '2%' }}>
          Deleting the the case wil permanently delete all the information that was stored in this form.
        </DialogContentText>
        <DialogContentText sx={{ marginBottom: '5%', marginLeft: '2%' }}>
          Are you sure you would like to delete this case?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleDeleteCase} variant="contained" sx={{ backgroundColor: 'red' }}>
          Delete Case
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCaseModal;
