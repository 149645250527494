import React from 'react';
import Miller from "../images/miller.jpg"; // Make sure the path is correct and the image exists in this location

export default function Millerr() {
    return (
        <div style={{ textAlign: 'center' }}>  
            <h3>Immpact & Marc Miller</h3>
            <img 
                src={Miller} 
                alt="Marc Miller" 
                style={{ 
                    width: '200px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
        </div>
    )
}

