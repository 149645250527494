import React, { useEffect, useState } from 'react';
import VideoThumb from '../images/Thumb.png';
import ModalVideo from '../components/modal-video';
import MyVideo from '../images/6.png';
import { useTranslation } from 'react-i18next';
import Iconify from 'components/iconify';
import { useStripe } from 'utils/hooks';

export default function Hero() {
  const [modalOpen, setModalOpen] = useState(false);
  const [platform, setPlatform] = useState<string | undefined>(undefined);
  const { redirectToCheckout } = useStripe();
  const { t } = useTranslation();

  useEffect(() => {
    console.log(navigator.userAgent);
    if (navigator.userAgent.includes('Win')) {
      setPlatform('Win');
    } else if (navigator.userAgent.includes('Mac')) {
      setPlatform('Mac');
    } else if (navigator.userAgent.includes('Linux')) {
      setPlatform('linux');
    }
  }, []);
  return (
    <section className="relative">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          {/* ... (your SVG code here) */}
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="text-center pb-12 md:pb-16">
            <h1
              className="tw-h1 text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
              data-aos="zoom-y-out"
            >
              {t('description.titlePreHighlight')}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                {t('description.titleHighlight')}
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                {t('description.subtitle')}
              </p>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <div
                  className="tw-a btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0 cursor-pointer"
                  onClick={redirectToCheckout}
                >
                  {t('description.trialBtn')}
                </div>

                {/* <div>
                  {platform === 'Mac' ? (
                    <a
                      className="tw-a btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4"
                      href="https://github.com/Immpact/immpact-app-release/releases/download/1.1.30/Immpact-app-1.1.30-universal.dmg"
                      download
                    >
                      <Iconify icon="mdi:download" className="mr-2" /> {t('description.learnMoreBtn')}
                    </a>
                  ) : platform === 'Win' ? (
                    <a
                      className="tw-a btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4"
                      href="https://github.com/Immpact/immpact-app-release-win/releases/download/1.1.30/Immpact-app.1.1.30.msi"
                      download
                    >
                      <Iconify icon="mdi:download" className="mr-2" /> {t('description.learnMoreBtn')}
                    </a>
                  ) : null}
                </div> */}
              </div>
            </div>
          </div>

          {/* Modal Video */}
          <ModalVideo
            thumb={VideoThumb}
            thumbWidth={768}
            thumbHeight={432}
            thumbAlt="Modal video thumbnail"
            video={MyVideo}
            videoWidth={1920}
            videoHeight={1080}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          />
        </div>
      </div>
    </section>
  );
}
