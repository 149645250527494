import { Checkbox } from '@mui/material';

const CheckboxInput = ({ defaultValue, onChange }) => {
  return (
    <>
      <Checkbox
        sx={{ marginLeft: '1.5rem', marginBottom: '5%' }}
        checked={defaultValue === 'off'}
        onChange={(e) => {
          e.target.value = e.target.value == 'on' ? 'off' : 'on';
          onChange(e);
        }}
      />
    </>
  );
};

export default CheckboxInput;
