import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useApiClient } from 'apiClient';
import { StandardInput } from 'components/form-input';
import Iconify from 'components/iconify';
import { AstPath } from 'prettier';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendMessage } from 'redux/alertSlice';
import { AlertSeverity } from 'types';
import { useTranslation } from 'react-i18next';
import { create, set } from 'lodash';

interface AddUserModalProps {
  open: boolean;
  handleClose: () => void;
}

const AddUserModal = ({ open, handleClose }: AddUserModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createRepCode, createUser } = useApiClient();
  const [repCode, setRepCode] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  useEffect(() => {
    if (open && !repCode) {
      createRepCode().then((response) => {
        setRepCode(response);
      });
    }
  }, [open]);

  const handleNewClient = () => {
    createUser(repCode, firstName, lastName, email);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: '5%', marginLeft: '2%' }}>{t('dashboard.createNewClient')}</DialogTitle>
      <DialogContent sx={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <DialogContentText sx={{ marginBottom: '5%', marginLeft: '2%' }}>
          {t('dashboard.createNewClientDesc')}
        </DialogContentText>
        <TextField
          sx={{ marginBottom: '5%', width: '100%' }}
          label="First Name"
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
        <TextField
          sx={{ marginBottom: '5%', width: '100%' }}
          label="Last Name"
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
        <TextField
          sx={{ marginBottom: '5%', width: '100%' }}
          type="email"
          label="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <TextField
          value={repCode}
          name="Rep ID"
          label="Rep ID"
          contentEditable={false}
          disabled
          sx={{ width: '100%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(repCode);
                    dispatch(
                      sendMessage({
                        open: true,
                        message: 'Copied to clipboard',
                        severity: AlertSeverity.SUCCESS,
                      })
                    );
                  }}
                  edge="end"
                >
                  <Iconify icon={'ph:clipboard'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleNewClient}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
