import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { populatePages } from 'redux/formSlice';
import { icon } from 'components/iconify';
import { useSelector } from 'react-redux';

// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { useTranslation } from 'react-i18next';
import {useImmpactRoutes} from 'utils/hooks/useImmpactRoutes';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const uid = useSelector((state) => state.account.uid);
  const { getInitialDashbaordSidbar, getCasesDashbaordSidbar, getDependantsDashbaordSidbar  } = useImmpactRoutes();

  useEffect(() => {
    if (location?.pathname?.includes('cases')) {
      dispatch(populatePages(getCasesDashbaordSidbar(uid)));
    } else if (location?.pathname?.includes('dependents')) {
      dispatch(populatePages(getDependantsDashbaordSidbar()));
    } else if (
      location?.pathname?.includes('user') ||
      location?.pathname?.includes('settings')
    ) {
      dispatch(populatePages(getInitialDashbaordSidbar()));
    }
  }, [location]);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
