import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setInputHidden, clearValue } from 'redux/formSlice';
import { Input, ReducerState } from 'types';

export const useDependentInputs = (input: Input, sectionIndex: number) => {
  const dispatch = useDispatch();
  const section = useSelector((state: ReducerState) => state.form.currentPage.sections[sectionIndex]);
  const caseId = useSelector((state: ReducerState) => state.client.currentCase);
  const { dependentId = '', pageId = '' } = useParams();

  const getsessionStorageString = (pathToValue: string[]) => {
    let localId = pageId + '/' + caseId + '/return/' + dependentId;
    if (pathToValue instanceof Array) {
      for (var wordIndex in pathToValue) {
        localId = localId + '/' + pathToValue[wordIndex];
      }
      return localId;
    } else {
      return '';
    }
  };

  const findInputIndexById = (id: string, sectionIndex: number) => {
    // find the input with path to value equal to the id of the triggered inpt and return its index
    const inputIndex = section.inputs.findIndex((input) => input.elementId === id);
    return inputIndex;
  };

  const findInputById = (id: string, sectionIndex: number) => {
    // find the input with path to value equal to the id of the triggered inpt and return its index
    const input = section.inputs.find((input) => input.elementId === id);
    return input;
  };

  const hasValue = (input: Input) => {
    const value = sessionStorage.getItem(getsessionStorageString(input.pathToValue?.['0']));
    return !!(value || !!input.value);
  };

  const triggerSubsection = (value: string) => {
    const closeTriggersRecursively = (val: string, ipt: Input = {} as Input) => {
      let valueIdx = -1;
      // iterate through the triggers of the input and check the ones corresponding to the value
      for (var i = 0; i < ipt.triggers.length; i++) {
        if (ipt.triggers[i].value === value) {
          valueIdx = i;
        }

        // close all inputs ids related to this specific trigger
        const elementIds = ipt.triggers?.[i]?.elementIds || [];
        for (var idx in elementIds) {
          dispatch(
            setInputHidden({
              hidden: true,
              sectionIndex,
              inputIndex: findInputIndexById(elementIds[idx], sectionIndex),
            })
          );
          const input = findInputById(elementIds[idx], sectionIndex);

          // if the closed trigger contains triggers, close them too
          if (input?.triggers?.length && hasValue(input)) {
            dispatch(clearValue({ sectionIndex, inputIndex: findInputIndexById(elementIds[idx], sectionIndex) }));
            sessionStorage.removeItem(getsessionStorageString(input.pathToValue?.['0']));
            closeTriggersRecursively(val, findInputById(elementIds[idx], sectionIndex));
          }
        }
      }
      return valueIdx;
    };
    const openTriggersRecursively = (valueIdx: number, ipt: Input = {} as Input) => {
      const elementIds = ipt.triggers?.[valueIdx]?.elementIds || [];
      for (var idx in elementIds) {
        dispatch(
          setInputHidden({
            hidden: false,
            sectionIndex,
            inputIndex: findInputIndexById(elementIds[idx], sectionIndex),
          })
        );
        const input = findInputById(elementIds[idx], sectionIndex);
        if (input?.triggers?.length && hasValue(input)) {
          openTriggersRecursively(valueIdx, findInputById(elementIds[idx], sectionIndex));
        }
      }
    };
    const valueIdx = closeTriggersRecursively(value, input);
    openTriggersRecursively(valueIdx, input);
  };
  return { triggerSubsection };
};
