export enum InputType {
  RADIO = 'RADIO',
  DROPDOWN = 'DROPDOWN',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_TEXT = 'INPUT_TEXT',
  TEXT_BOX = 'TEXT_AREA',
  DATE = 'DATE_INPUT',
  BUTTON = 'BUTTON_INPUT',
  DATE_YM = 'DATE_YM',
  CHECKBOX = "CHECKBOX"
}

export enum AlertSeverity {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum LoginStatus {
  LOGGED_OUT = 'logged_out',
  NOT_SETUP = 'not_setup',
  FULLY_SETUP = 'fully_setup',
}

export enum AccountType {
  USER = "User", 
  REP = "Rep"
}

export interface Value {
    value: string | Date | number;
    format?: string;
}

export interface DropDownOption {
  key: string;
  value: string;
}

export interface Trigger {
  value: string; 
  elementIds: string[];
}

export interface Input {
  elementId: string;
  prompt: {
    [key: string]: string;
  };
  inputType: InputType;
  value?: {
    value: string | Date | number;
    format?: string;
    prompt?: {
      [key: string]: string;
    }
  };
  pathToValue: {
    [key: string]: string[];
  };
  options: DropDownOption[];
  hidden?: boolean;
  triggers: Trigger[]
  validation?: [];
}

export interface Section {
  id: string;
  name: string;
  isLastIndex?: boolean;
  idxCount: number;
  hidden: boolean;
  inputs: Input[];
  collapsed: boolean;
}

export interface Page {
  name: {
    [key: string]: string;
  };
  sections: Section[];
}

export interface PageMetadata {
  name: string;
  id: string;
  icon: string;
}

export interface User {
  id: string;
  givenName: string;
  lastName: string;
  email: string;
  caseType: string;
  status: string;
}

export interface Case {
  id(id: any): { payload: any; type: "client/populateCurrentCase"; };
  caseName: string
}

export interface Holder {
  pathToValue: string[], 
  value: string,
}

export interface Dependant {
  id: string; 
  formType: Value;
  givenName?: Value; 
  lastName?: Value;
}

export interface ReducerState {
  login: {
    value: boolean;
    status: LoginStatus
    entryChecked: boolean; 
  };
  form: {
    pages: PageMetadata[];
    currentPage: Page;
    dependants: Dependant;
    returnObject: {}
  };
  alert: {
    open: boolean;
    message: string;
    severity: AlertSeverity;
  };
  client: {
    value: User[];
    currentCase: string;
    currentUser: Case[];
  };
  account: {
    token: string;
    accountType: AccountType; 
    uid: string;
  }, 
  return: {
    returnObject: {}
  }
}
