import React from 'react';
import Step1 from "../images/step1.png";
import Step2 from "../images/step2.png";
import Step3 from "../images/step3.png";
import Step4 from "../images/step4.png";
import Step5 from "../images/step5.png";
import Step6 from "../images/step6.png";
import Step7 from "../images/step7.png";
import Step8 from "../images/step8.png";
import Step9 from "../images/step9.png";
import Step10 from "../images/step10.png";
import Step11 from "../images/step11.png";
import Step12 from "../images/step12.png";
import Step13 from "../images/step13.png";
import Step14 from "../images/step14.png";
import Step15 from "../images/step15.png";


export default function Guide() {
    return (
        <div style={{ textAlign: 'center' }}>  
            <h3>Step 1: Download “Immpact” on the landing page.</h3>
            <img 
                src={Step1} 
                alt="step 1" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 2: As a Consultant, sign in and go to your dashboard - You can add a client by clicking "Add New Client".</h3>
            <img 
                src={Step2} 
                alt="step 2" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 3: Fill in the appropriate data and copy your unique "Rep Code"</h3>
            <h5>*note: the email you input should be exactly the same email your client makes their account with.</h5>
            <img 
                src={Step3} 
                alt="step 3" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 4: Once the client has been added to your dashboard, click the 3 dots on the right hand side and "View Cases" to continue.</h3>
            <img 
                src={Step4} 
                alt="step 4" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 5: Currently, your client doesn't have any cases associated to them, let's add one by clicking "Add New Case"!</h3>
            <img 
                src={Step5} 
                alt="step 5" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 6: Fill in the appropriate information by giving your case a name and input the type of case you're working on.</h3>
            <img 
                src={Step6} 
                alt="step 6" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 7: Your client can now freely create their account and click "I am an applicant"</h3>
            <img 
                src={Step7} 
                alt="step 7" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 8: They should be instructed to input the "Rep Code" that you have sent to them in order to link their account to yours.</h3>
            <img 
                src={Step8} 
                alt="step 8" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 9: You and your client's accounts are now linked - Both of you can "Edit Case" to proceed.</h3>
            <img 
                src={Step9} 
                alt="step 9" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 10: Both representatives and applicants can choose to "Add Dependant" or "Edit applicant" by clicking the 3 dots on the right hand side.</h3>
            <img 
                src={Step10} 
                alt="step 10" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 11: Inputs can be filled by either party and should be reviewed by the representative. These inputs consists of all possible information that is required to successfully complete the case.</h3>
            <img 
                src={Step11} 
                alt="step 11" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 12: Once all the necessary inputs have been filled out. The representative can go to the "Cases" page and click "Start Automation", autopopulating the case on the portal.</h3>
            <h5 style={{ color: 'red' }}>*Note: This step only works if you are working on the "Immpact-app", which was downloaded during step 1</h5>
            <img 
                src={Step12} 
                alt="step 12" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 13: You will be prompted to log in to your respective portal as usual and navigate to the "Profile Page" after creating a new case for your client. </h3>
            <h5 style={{ color: 'red' }}>*At Immpact we prioritze security: We do not have access to your login information, nor do we have access to any data beyond this point. Your account infomation is secure - for more information please visit our privacy policy and our security details. </h5>
            <img 
                src={Step13} 
                alt="step 13" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 14: Once you find yourself on the "Profile page", click "Start Automation" and watch as the case gets filled before your very own eyes!</h3>
            <h5>*Note: You can "Stop Automation" any time during this process.</h5>
            <h5>These inputs can later be re-used for other cases as well, saving you even more time!</h5>
            <img 
                src={Step14} 
                alt="step 14" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            <h3>Step 15: When completing the case (or even while filling in the inputs) Representatives and clients have access to the IPR (Input Proof Report) which tracked the case progress.</h3>
            <img 
                src={Step15} 
                alt="step 15" 
                style={{ 
                    width: '600px', 
                    marginBottom: '20px', 
                    borderRadius: '10px', // Rounds the edges of the image
                    display: 'block', // Ensures the image is a block-level element
                    marginLeft: 'auto',  // Together with marginRight centers the image
                    marginRight: 'auto'
                }} 
            />
            
        </div>
    )
}

