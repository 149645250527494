'use client';

import { useState, useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { Link } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { useStripe } from 'utils/hooks';

const lngs = {
  en: { flag: '🇬🇧' }, // English flag emoji
  fr: { flag: '🇫🇷' }, // French flag emoji
};

export default function MobileMenu() {
  const { t, i18n } = useTranslation();
  const { redirectToCheckout } = useStripe();

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en');
  };

  // Get the current language flag
  const currentFlag = i18n.language === 'en' ? '🇫🇷' : '🇬🇧';

  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  const trigger = useRef<HTMLButtonElement>(null);
  const mobileNav = useRef<HTMLDivElement>(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }: { target: EventTarget | null }): void => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target as Node) || trigger.current.contains(target as Node))
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }): void => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="flex md:hidden">
      {/* Hamburger button */}
      <button
        ref={trigger}
        className={`hamburger ${mobileNavOpen && 'active'}`}
        aria-controls="mobile-nav"
        aria-expanded={mobileNavOpen}
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
      >
        <span className="sr-only">Menu</span>
        <svg className="w-6 h-6 fill-current text-gray-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <rect y="4" width="24" height="2" />
          <rect y="11" width="24" height="2" />
          <rect y="18" width="24" height="2" />
        </svg>
      </button>

      {/*Mobile navigation */}
      <div ref={mobileNav}>
        <Transition
          show={mobileNavOpen}
          as="nav"
          id="mobile-nav"
          className="absolute top-0 h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white"
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-100"
        >
          <div className="flex justify-end items-center p-4">
            {/* Close button */}
            <button onClick={() => setMobileNavOpen(false)} className="p-2">
              <svg
                className="w-6 h-6 text-gray-900"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <ul className="tw-ul px-5 py-2">
            <li>
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                onClick={() => {
                  setMobileNavOpen(false);
                  redirectToCheckout();
                }}
              >
                <Trans i18nKey="description.part1"></Trans>
              </Link>
            </li>
            <li>
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2"
                onClick={() => {
                  setMobileNavOpen(false);
                  redirectToCheckout();
                }}
              >
                <Trans i18nKey="description.part2"> </Trans>
                <svg
                  className="w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                    fill="#999"
                    fillRule="nonzero"
                  />
                </svg>
              </Link>
            </li>
          </ul>

          <div>
            {Object.keys(lngs).map((lng) => (
              <button
                style={{
                  fontSize: '1.5em',
                  padding: '5px',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                }}
                onClick={toggleLanguage}
              >
                {currentFlag}
              </button>
            ))}
          </div>
        </Transition>
      </div>
    </div>
  );
}
