import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useApiClient } from 'apiClient';
import { StandardInput, customStyles } from 'components/form-input';
import DateInput from 'components/form-input/DateInput';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { ReducerState } from 'types';

interface UpdateUserModal {
  open: boolean;
  handleClose: () => void;
  isEdit?: boolean;
  editId?: string;
}

const getRandomId = () => {
  const timestamp = new Date().getTime();

  const string36 = timestamp.toString(36).toUpperCase();

  return string36;
};

const THRESHOLD = 5;

const DEPENDANT_TYPES: {
  label: { en: string };
  value: string;
}[] = [
  {
    label: {
      en: 'Type A Dependant',
    },
    value: '1: 01',
  },
  {
    label: {
      en: 'Type B Dependant',
    },
    value: '2: 02',
  },
  {
    label: {
      en: 'Type C Dependant',
    },
    value: '3: 03',
  },
];

const DEPENDENT_OPTIONS: {
  label: { en: string };
  value: string;
}[] = [
  {
    label: {
      en: 'Adopted Child',
    },
    value: '1: 87',
  },
  {
    label: {
      en: 'Child',
    },
    value: '2: 02',
  },
  {
    label: {
      en: 'Common-Law Partner',
    },
    value: '3: 01',
  },
  {
    label: {
      en: 'Grandchild',
    },
    value: '4: 04',
  },
  {
    label: {
      en: 'Other',
    },
    value: '5: 85',
  },
  {
    label: {
      en: 'Spouse',
    },
    value: '6: 10',
  },
  {
    label: {
      en: 'Step-Child',
    },
    value: '7: 03',
  },
  {
    label: {
      en: 'Step-Grandchild',
    },
    value: '8: 100',
  },
  {
    label: {
      en: 'Parent',
    },
    value: '9: 05',
  },
  {
    label: {
      en: 'Adoptive Parent',
    },
    value: '10: 37',
  },
];

export const AddDependentModal = ({ open, handleClose, isEdit = false, editId = '' }: UpdateUserModal) => {
  const DEPENDENT_OPTIONS: {
    label: { en: string };
    value: string;
  }[] = [
    {
      label: {
        en: 'Adopted Child',
      },
      value: '1: 87',
    },
    {
      label: {
        en: 'Child',
      },
      value: '2: 02',
    },
    {
      label: {
        en: 'Common-Law Partner',
      },
      value: '3: 01',
    },
    {
      label: {
        en: 'Grandchild',
      },
      value: '4: 04',
    },
    {
      label: {
        en: 'Other',
      },
      value: '5: 85',
    },
    {
      label: {
        en: 'Spouse',
      },
      value: '6: 10',
    },
    {
      label: {
        en: 'Step-Child',
      },
      value: '7: 03',
    },
    {
      label: {
        en: 'Step-Grandchild',
      },
      value: '8: 100',
    },
    {
      label: {
        en: 'Parent',
      },
      value: '9: 05',
    },
    {
      label: {
        en: 'Adoptive Parent',
      },
      value: '10: 37',
    },
  ];

  const FILTERED_DEPENDENT_OPTIONS = DEPENDENT_OPTIONS.map((option) => ({
    label: option?.label?.en,
    value: option?.value,
  }));

  const FILTERED_DEPENDENT_TYPES = DEPENDANT_TYPES.map((option) => ({
    label: option?.label?.en,
    value: option?.value,
  }));

  const [depId, setDepId] = useState<string>('');
  const [notAccompanying, setNotAccompanying] = useState<boolean | null>(null);
  const [d1State, setD1State] = useState<boolean>(false);
  const [d2State, setD2State] = useState<boolean>(false);
  const [d3State, setD3State] = useState<boolean>(false);
  const [d4State, setD4State] = useState<boolean>(false);
  const [d5State, setD5State] = useState<boolean>(false);

  const caseId = useSelector((state: ReducerState) => state.client.currentCase);

  const { savePage, getDependents } = useApiClient();

  useEffect(() => {
    if (isEdit) {
      setDepId(editId);
    } else {
      setDepId(getRandomId());
    }
  }, [open]);

  const getsessionStorageString = (pathToValue: string[]) => {
    let localId = 'newDep/' + caseId + '/return/' + depId;
    if (pathToValue instanceof Array) {
      for (var wordIndex in pathToValue) {
        localId = localId + '/' + pathToValue[wordIndex];
      }
      return localId;
    } else {
      return '';
    }
  };

  const handleChange = (prompt: string, value: any, text: string, pathToValue: any) => {
    for (var ptvKey in pathToValue) {
      sessionStorage.setItem(
        getsessionStorageString(pathToValue[ptvKey]),
        JSON.stringify({ value, prompt, text, isImmpactVal: true })
      );
    }
  };

  const discardAndClose = () => {
    const keys = Object.keys(sessionStorage).filter((o) => o.startsWith('newDep/' + caseId + '/return/' + depId));
    keys.forEach((key) => {
      sessionStorage.removeItem(key);
    });
    setD1State(false);
    setD2State(false);
    setD3State(false);
    setD4State(false);
    setD5State(false);
    handleClose();
  };

  const handleNewClient = async () => {
    await savePage('newDep');
    await getDependents();
    discardAndClose();
  };

  return (
    <Dialog open={open} onClose={discardAndClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: '5%', marginLeft: '2%' }}>
        {isEdit ? 'Change dep to new data' : 'Add Dependent'}
      </DialogTitle>
      <DialogContent sx={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <DialogContentText sx={{ marginBottom: '1%', marginLeft: '2%' }}>
          General information on dependent
        </DialogContentText>
        <Typography
          sx={{
            marginLeft: '1.7rem',
            marginBottom: '0.5rem',
            height: '3em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          Is dependent accompanying you?
        </Typography>
        <RadioGroup
          sx={{ marginLeft: '1.7rem' }}
          onChange={(e) => {
            if (e?.target?.value === 'dependantNo') {
              setNotAccompanying(true);
            } else {
              setNotAccompanying(false);
            }
            handleChange('Is dependent accompanying you?', e?.target?.value, e?.target?.value, {
              '0': ['dependantYes'],
            });
            setD1State(true);
          }}
        >
          <FormControlLabel value="dependantYes" control={<Radio />} label="Yes" />
          <FormControlLabel value="dependantNo" control={<Radio />} label="No" />
        </RadioGroup>
        {notAccompanying && (
          <>
            <Typography
              sx={{
                marginLeft: '1.7rem',
                marginBottom: 'rem',
                height: '3em',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              Reason not accompanying?
            </Typography>
            <StandardInput
              placeholder="Last Name..."
              sx={{ marginBottom: 0 }}
              onChange={(e) => {
                handleChange('Last Name?', e?.target?.value, e?.target?.value, {
                  '0': ['dependantDetailsForm-reasonNotAccompanying'],
                });
                setD4State(true);
              }}
            />
          </>
        )}
        {notAccompanying === false && (
          <>
            <Typography
              sx={{
                marginLeft: '1.7rem',
                marginBottom: 'rem',
                height: '3em',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              Dependant Type?
            </Typography>
            <ReactSelect
              menuPortalTarget={document.body}
              styles={customStyles}
              options={FILTERED_DEPENDENT_TYPES}
              onChange={(selection) => {
                handleChange('Dependant Type?', selection?.value, selection?.label || '', {
                  '0': ['dependantDetailsForm-dependantType'],
                });
                setD2State(true);
              }}
              minMenuHeight={200}
              maxMenuHeight={200}
            />
          </>
        )}
        <Typography
          sx={{
            marginLeft: '1.7rem',
            marginBottom: 'rem',
            height: '3em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          Relationship to applicant?
        </Typography>
        <ReactSelect
          menuPortalTarget={document.body}
          styles={customStyles}
          options={FILTERED_DEPENDENT_OPTIONS}
          onChange={(selection) => {
            handleChange('Relationship to applicant?', selection?.value, selection?.label || '', {
              '0': ['dependantForm-relationship'],
            });
            setD2State(true);
          }}
          minMenuHeight={200}
          maxMenuHeight={200}
        />
        <Typography
          sx={{
            marginLeft: '1.7rem',
            height: '3em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          Given Name
        </Typography>
        <StandardInput
          placeholder="Given Name..."
          onChange={(e) => {
            handleChange('Given Name?', e?.target?.value, e?.target?.value, { '0': ['dependantForm-givenName'] });
            setD3State(true);
          }}
        />
        <Typography
          sx={{
            marginLeft: '1.7rem',
            height: '1.5em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          Last Name
        </Typography>
        <StandardInput
          placeholder="Last Name..."
          onChange={(e) => {
            handleChange('Last Name?', e?.target?.value, e?.target?.value, { '0': ['dependantForm-familyName'] });
            setD4State(true);
          }}
        />
        <Typography
          sx={{
            marginLeft: '1.7rem',
            height: '1.5em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          Date Of Birth?
        </Typography>
        <DateInput
          onChange={(e) => {
            handleChange('Date Of Birth?', e?.$d, e?.$d, { '0': ['personalDetailsForm-dob'] });

            // check if the user is over 18
            const today = new Date();
            const birthDate = new Date(e?.$d);
            const age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if ((m >= 0 && age >= 18) || (m < 0 && age > 18)) {
              handleChange('Is dependent over 18?', 'Dependants18Plus', 'Dependants18Plus', { '0': ['formType'] });
            } else {
              handleChange('Is dependent over 18?', 'DependantsMinor', 'DependantsMinor', { '0': ['formType'] });
            }
            setD5State(true);
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={!(d1State && d2State && d3State && d4State && d5State)} onClick={handleNewClient}>
          {isEdit ? 'Save' : 'Add'}
        </Button>
        <Button onClick={discardAndClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDependentModal;
