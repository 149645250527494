import React from 'react';
import Layout from 'landing/app/(default)/layout';
import Home from './app/(default)/page';
import RootLayout from './app/layout';

const LandingPage = () => {
  return (
      <RootLayout>
        <Layout>
          <Home />
        </Layout>
      </RootLayout>
  );
};

export default LandingPage;