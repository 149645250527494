import { createSlice } from '@reduxjs/toolkit';
import { Page, PageMetadata, ReducerState } from 'types/types';
import { icon } from 'components/iconify';

const formSlice = createSlice({
  name: 'form',
  initialState: {

    pages: [

    ] as unknown as PageMetadata[],
    currentPage: {} as Page,
    dependants: {},
    isCurrentPageLoading: false,
    cancelFunction: null,
  },
  reducers: {
    populatePages: (state, action) => {
      state.pages = action.payload;
    },
    clearCurrentPage: (state) => {
      state.currentPage = {} as Page;
    },
    populateCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    populateDependants: (state, action) => {
      state.dependants = action.payload;
    },

    setCancelFunction: (state, action) => {
      state.cancelFunction = action.payload;
    },
    setIsCurrentPageLoading: (state, action) => {
      state.isCurrentPageLoading = action.payload;
    },

    // INTERNAL UPDATORS
    updateSection: (state, action) => {
      state.currentPage.sections[action.payload.sectionIndex] = action.payload.section;
    },

    setIdxCount: (state, action) => {

      state.currentPage.sections[action.payload.sectionIndex].idxCount =
        action.payload.idxCount;
    },
    setInputHidden: (state, action) => {
      // TODO: save values
      state.currentPage.sections[action.payload.sectionIndex].inputs[action.payload.inputIndex].hidden =
        action.payload.hidden;
    },

    clearValue: (state, action) => {
      state.currentPage.sections[action.payload.sectionIndex].inputs[action.payload.inputIndex].value = undefined;
    },

    addNextSection: (state, action) => {
      // TODO: save values
      state.currentPage.sections.splice(action.payload.sectionIndex + 1, 0, action.payload.section);
    },
    removeSection: (state, action) => {
      // TODO: save values
      state.currentPage.sections.splice(action.payload.sectionIndex, 1);
    },
    makeLastIndex: (state, action) => {
      // TODO: save values
      state.currentPage.sections[action.payload.sectionIndex].isLastIndex = action.payload.value;
    },
    toggleSectionCollapse: (state, action) => {
      state?.currentPage.sections[action.payload.sectionIndex]?.collapsed == undefined
        ? (state.currentPage.sections[action.payload.sectionIndex].collapsed = true)
        : (state.currentPage.sections[action.payload.sectionIndex].collapsed =
          !state.currentPage.sections[action.payload.sectionIndex].collapsed);
    },

  },
});


export const {
  setIdxCount,
  setCancelFunction,
  populatePages,
  clearCurrentPage,
  populateCurrentPage,
  populateDependants,
  updateSection,
  clearValue,
  setInputHidden,
  addNextSection,
  removeSection,
  makeLastIndex,
  setIsCurrentPageLoading,
  toggleSectionCollapse,
} = formSlice.actions;
export default formSlice;
