import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./loginSlice";
import formSlice from './formSlice'; 
import alertSlice from "./alertSlice";
import clientSlice from "./clientSlice";
import accountSlice from "./accountSlice";
import returnSlice from "./returnSlice";

export const store = configureStore({
  reducer: {
    login: loginSlice.reducer,
    form: formSlice.reducer, 
    alert: alertSlice.reducer,
    client: clientSlice.reducer,
    account: accountSlice.reducer, 
    return: returnSlice.reducer
  },
});

export default store;