import React from 'react';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { sample } from 'lodash';
import { faker } from '@faker-js/faker';
import { useApiClient } from 'apiClient';
import { useSelector } from 'react-redux';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const useIPR = () => {
  const { getIPR } = useApiClient();
  const generateIPR = async (caseId) => {
    const general = await getIPR(caseId);
    const result = general.IPR;
    let inputProofReport = [];
    for (const input in result) {
      const dt = new Date(result[input].timeStamp);
      const formatted =
        dt.getFullYear() +
        '-' +
        (dt.getMonth() + 1) +
        '-' +
        dt.getDate() +
        ' ' +
        dt.getHours() +
        ':' +
        dt.getMinutes() +
        ':' +
        dt.getSeconds();
      inputProofReport.push([
        { text: result[input].prompt[result[input].language], height: 40, width: 50 },
        { text: result[input].text, width: 50 },
        { text: result[input].editor, width: 50 },
        { text: result[input].language, width: 50 },
        { text: formatted, width: 50 },
      ]);
    }
    const repName = general.repName; 
    const clientName = general.clientName; 
    const caseType = general.caseType || "Permanent Residence";
    // get currentDate in YYYY-MM-DD format
    const currentDate = new Date().toISOString().slice(0, 10);
    var dd = {
      content: [
        { text: 'INPUT PROOF REPORT', style: 'header', alignment: 'center', margin: [0, 0, 0, 40] },
        {
          //  alignment: 'justify',
          columnGap: 20,
          columns: [
            {
              text: [
                'Client: ',
                { text: `${clientName} \n`, bold: true },
                'Representative: ',
                { text:  `${repName} \n`, bold: true },
                'Date: ',
                { text: `${currentDate} \n`, bold: true },
                'Case Type: ',
                { text: caseType, bold: true },
              ],
            },
            {
              text: [{ text: 'IMMPACT Inc.', style: 'subheader' }],
              alignment: 'right',
            },
          ],
          margin: [0, 0, 0, 20],
        },
        {
          style: 'tableExample',
          table: {
            heights: function (row: number) {
              if (row === 0) {
                return 20;
              } else {
                return 40;
              }
            },
            widths: [100, 100, 100, 75, 75],
            body: [
              [
                { text: 'Input', bold: true },
                { text: 'Value', bold: true },
                { text: 'Edited By', bold: true },
                { text: 'Language', bold: true },
                { text: 'Date/Time', bold: true },
              ],
              ...inputProofReport,
            ],
          },
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };
    pdfMake.createPdf(dd).download();
  };
  return { generateIPR };
};
