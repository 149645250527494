import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { alpha, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { Input, ReducerState } from 'types';

const StyledDate = styled(DatePicker)(({ theme }) => ({
  width: '90%',
  marginLeft: '1.7rem',
  marginBottom: '5%',
  maxHeight: '50px !important',
  '& fieldset': {
    borderWidth: `1px !important`,
    height: '60px',
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

interface DateInputProps {
  isMonthYear?: boolean;
  input?: Input;
  defaultValue?: string;
  onChange?: (e: any) => void;
}

const DateInput = ({ isMonthYear = false, input, defaultValue, onChange }: DateInputProps) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDate
          views={isMonthYear ? ['year', 'month'] : ['day', 'month', 'year']}
          onChange={onChange}
          value={dayjs(new Date(defaultValue || '').toString())}
          format={isMonthYear ? 'YYYY/MM' : 'YYYY/MM/DD'}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateInput;
