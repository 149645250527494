import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Modal,
  OutlinedInput,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useApiClient } from 'apiClient';
import { StandardInput } from 'components/form-input';
import Iconify from 'components/iconify';
import { AstPath } from 'prettier';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

const ReportProblemModal = ({ open, handleClose }: ModalProps) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const { reportProblem } = useApiClient();
  const handleSubmit = async () => {
    reportProblem(title, description);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: '5%', marginLeft: '2%' }}>{t('dashboard.report')}</DialogTitle>
      <DialogContent sx={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <DialogContentText sx={{ marginBottom: '5%', marginLeft: '2%' }}>
          {t('dashboard.reportDesc')}
        </DialogContentText>
        <StandardInput
          placeholder="Title..."
          type="text"
          onChange={(e) => {
            setTitle(e?.target?.value);
          }}
        />

        <StandardInput
          placeholder={t('dashboard.problemDesc')}
          multiline
          rows={6}
          type="text"
          onChange={(e) => {
            setDescription(e?.target?.value);
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit}>{t('dashboard.reportSubmit')}</Button>
        <Button onClick={handleClose}>{t('dashboard.reportCancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportProblemModal;
