import { useTranslation } from 'react-i18next';

export default function About() {

    const { t } = useTranslation();

    return (
        <div style={{ textAlign: 'center' }}>  
            <h2> {t('about.aboutHeader')}</h2>
            <p>
            {t('about.aboutDesc')}
            </p>
            <h3> {t('about.beginningsHeader')}</h3>
            <p>
            {t('about.beginningsDesc')}
            </p>
            <h3> {t('about.innovationHeader')}</h3>
            <p>
            {t('about.innovationDesc')}
            </p>
            <h3> {t('about.utilityHeader')}</h3>
            <p>
            {t('about.utilityDesc')}
            </p>
            <h3> {t('about.visionHeader')}</h3>
            <p>
            {t('about.visionDesc')}
            </p>
            <p>
            {t('about.finalStatement')}
            </p>
        </div>
    )
}
