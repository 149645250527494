// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDD85zB3Whf_Q6sOYOyl2vdz9T961pCfL4",
  authDomain: "immigrationpro-6b3b5.firebaseapp.com",
  databaseURL: "https://immigrationpro-6b3b5-default-rtdb.firebaseio.com",
  projectId: "immigrationpro-6b3b5",
  storageBucket: "immigrationpro-6b3b5.appspot.com",
  messagingSenderId: "204588699789",
  appId: "1:204588699789:web:24385d4294b0b6b82652c4",
  measurementId: "G-0SHV1SLLFS",
  emulatorConfig: { host: 'localhost', port: 5001 },
};
if (process.env.REACT_APP_IS_PROD === 'false') {
  firebaseConfig.databaseURL = 'http://localhost:5001';
}
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

if (process.env.REACT_APP_IS_PROD === 'false') {
  fetch('http://localhost:9099').then(() => {
    connectAuthEmulator(auth, 'http://localhost:9099');
  })
}
const analytics = getAnalytics(app);